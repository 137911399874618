import React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Layout from "./components/Layout";
import "./index.css";
import "./input.css";
import FileUploadSearch from "./pages/FileUploadSearch";
import FileUploadList from "./pages/FileUploadList";
import FileUploadListViewOnly from "./pages/FileUploadListViewOnly";
import { LicenseInfo } from "@mui/x-license-pro";
import { SignInButton } from "./components/Buttons/SignInButton";
import { Route, Routes } from "react-router-dom";
import UploadHome from "./pages/UploadHome";
import Home from "./pages/Home";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Review from "./pages/Review";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/index";
import { DataProvider } from "./providers/DataProvider";
import Admin from "./pages/Admin";
import Payroll from "./pages/Payroll";
import ReviewId from "./pages/ReviewId";
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

const AuthWrapper = ({ children }) => {
  return (
    <div className="min-h-screen">
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className="flex min-h-[80vh] flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <div className="text-center">
              <h2 className="text-2xl font-semibold tracking-tight text-gray-900">
                Welcome
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500">
                Please sign in to access your account
              </p>
            </div>
            <div className="mt-8 flex justify-center">
              <SignInButton
                className="flex w-full max-w-[200px] justify-center rounded-md bg-primary-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                variant="contained"
              />
            </div>
          </div>
        </div>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <DataProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Layout>
            <Routes>
              <Route
                path="/"
                element={
                  <AuthWrapper>
                    <Home />
                  </AuthWrapper>
                }
              />
              <Route
                path="/admin"
                element={
                  <AuthWrapper>
                    <Admin />
                  </AuthWrapper>
                }
              />
              <Route
                path="/payroll"
                element={
                  <AuthWrapper>
                    <Payroll />
                  </AuthWrapper>
                }
              />
              <Route
                path="/upload"
                element={
                  <AuthWrapper>
                    <UploadHome />
                  </AuthWrapper>
                }
              />
              <Route
                path="/upload/create"
                element={
                  <AuthWrapper>
                    <FileUploadSearch />
                  </AuthWrapper>
                }
              />
              <Route
                path="/upload/create/:id"
                element={
                  <AuthWrapper>
                    <FileUploadList />
                  </AuthWrapper>
                }
              />
              <Route
                path="/upload/view/:id"
                element={
                  <AuthWrapper>
                    <FileUploadListViewOnly />
                  </AuthWrapper>
                }
              />
              <Route
                path="/review"
                element={
                  <AuthWrapper>
                    <Review />
                  </AuthWrapper>
                }
              />
              <Route
                path="/review/:id"
                element={
                  <AuthWrapper>
                    <ReviewId />
                  </AuthWrapper>
                }
              />
            </Routes>
          </Layout>
        </LocalizationProvider>
      </DataProvider>
    </ThemeProvider>
  );
}
