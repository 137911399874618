import React, { useMemo, useState, useCallback } from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  ColumnDef,
  FilterFn,
  getFilteredRowModel,
} from "@tanstack/react-table";
import {
  Stack,
  Tooltip,
  IconButton,
  AvatarGroup,
  Avatar,
  Chip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { EyeIcon, DocumentTextIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import { BonusProps, BuilderInfo } from "../../providers/types";
import BuilderSplitModal from "../../components/BuilderSplitModalReview";
import PayrollModal from "../../modals/PayrollModal";
import ReviewerViewModal from "../../modals/ReviewerViewModal";
import NoRows from "../DataGrid/animations/NoRows";
import AxiosInstance from "../../utils/axiosInstance";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

interface ReviewTableProps {
  data: BonusProps[];
  role?: string | null;
  loading: boolean;
  searchTerm?: string;
  selectedStatusType?: number | string | null;
  selectedCommunities?: string[];
  selectedRegion?: { regionName: string } | null;
}

export const ReviewTable: React.FC<ReviewTableProps> = ({
  data,
  role,
  loading,
  searchTerm = "",
  selectedStatusType,
  selectedCommunities = [],
  selectedRegion,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState<string | null>(null);
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());

  // Filter the data based on all criteria
  const filteredData = useMemo(() => {
    return data.filter((bonus) => {
      // Status filter
      if (selectedStatusType && selectedStatusType !== "All") {
        if (bonus.statusTypeId !== selectedStatusType) {
          return false;
        }
      }

      // Communities filter
      if (selectedCommunities.length > 0) {
        if (!selectedCommunities.includes(bonus.subdivision)) {
          return false;
        }
      }

      // Region filter
      if (selectedRegion && selectedRegion.regionName !== "All") {
        if (bonus.regionName !== selectedRegion.regionName) {
          return false;
        }
      }

      return true;
    });
  }, [data, selectedStatusType, selectedCommunities, selectedRegion]);

  function stringAvatar(name: string) {
    return {
      sx: {
        width: 24,
        height: 24,
      },
      children: `${name.split(" ")[0][0] || ""}${name.split(" ")[1][0] || ""}`,
    };
  }

  const downloadFile = async (url: string, filename: string) => {
    try {
      const response = await AxiosInstance.get(url, { responseType: "blob" });
      const blob = new Blob([response.data], { type: response.data.type });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("File download error:", error);
    }
  };

  const downloadSelectionSheet = async (jobId: number, bonus: any) => {
    const address = bonus.jobAddress.replace(/\s+/g, "");
    await downloadFile(
      `/api/cba/SelectionSheetsPdf/${jobId}`,
      `${bonus?.projectNumber || ""}${bonus?.projectNumber ? "-" : ""}${
        bonus?.jobNumber || ""
      }${bonus?.jobNumber ? "-" : ""}${address}-SelectionSheet.pdf`
    );
  };

  const downloadBuilderFolder = async (jobId: number, bonus: any) => {
    const address = bonus.jobAddress.replace(/\s+/g, "");
    await downloadFile(
      `/api/cba/mergedpdf/${jobId}`,
      `${bonus?.projectNumber || ""}${bonus?.projectNumber ? "-" : ""}${
        bonus?.jobNumber || ""
      }${bonus?.jobNumber ? "-" : ""}${address}-ConstructionFolder.pdf`
    );
  };

  const [bonusType, setBonusType] = useState<string>(
    localStorage.getItem("bonusType") || "ClosingBonus"
  );

  const handleChipClick = (jobId: number) => {
    setSelectedJobId(jobId.toString());
    setOpenDialog(true);
  };

  const handleConfirmUpload = async () => {
    if (!selectedJobId) return;
    try {
      await AxiosInstance.put(
        `/api/cba/updatebimuploadstatus/${selectedJobId}`
      );
      setOpenDialog(false);
    } catch (error) {
      console.error("Error updating BIM upload status:", error);
    }
  };

  const formatStatus = (status: number, bonus?: BonusProps): string => {
    const isDefaultDate = (date: string | Date) =>
      moment(date).isSame(moment("0001-01-01"));

    switch (status) {
      case 0:
        return "Not Started";
      case 1:
        return "In Upload Process";
      case 2:
        return "PM Review";
      case 3:
        return "SPM Review";
      case 4:
        return "DM Review";
      case 5:
        return "Ops Review";
      case 6:
        return bonusType === "ClosingBonus" ? "Ops Approved" : "Approved";
      case 7:
        return "Rejected";
      case 8:
        return "Busted";
      case 9:
        return bonus?.payrollProcessedOn &&
          !isDefaultDate(bonus.payrollProcessedOn)
          ? `In Payroll Process (${moment(bonus.payrollProcessedOn).format(
              "MM/DD/YYYY"
            )})`
          : "In Payroll Process";
      case 10:
        return bonus?.payrollPaidOn && !isDefaultDate(bonus.payrollPaidOn)
          ? `Paid (${moment(bonus.payrollPaidOn).format("MM/DD/YYYY")})`
          : "Paid";
      default:
        return "Unknown Status";
    }
  };

  const globalFilter: FilterFn<BonusProps> = (row, columnId, filterValue) => {
    const searchValue = filterValue.toString().toLowerCase();
    const values = [
      row.original.jobAddress,
      row.original.subdivision,
      row.original.regionName,
      row.original.payoutInfo?.projectNumber,
      row.original.jobNumber,
      formatStatus(row.original.statusTypeId, row.original),
    ];
    return values.some(
      (value) => value && value.toString().toLowerCase().includes(searchValue)
    );
  };

  const columns = useMemo<ColumnDef<BonusProps>[]>(
    () => [
      {
        id: "expand",
        size: 20,
        header: () => null,
        cell: ({ row }) => (
          <div className="flex justify-center">
            {expandedRows.has(row.original.jobId) ? (
              <ExpandLess className="text-gray-400" />
            ) : (
              <ExpandMore className="text-gray-400" />
            )}
          </div>
        ),
      },
      {
        accessorKey: "jobAddress",
        header: "Address",
        size: 200,
        cell: ({ row }) => (
          <Tooltip title={row.original.jobAddress}>
            <span className="truncate block max-w-[280px]">
              {row.original.jobAddress}
            </span>
          </Tooltip>
        ),
      },
      {
        accessorKey: "subdivision",
        header: "Community",
        size: 170,
      },
      // {
      //   accessorKey: "regionName",
      //   header: "Region",
      //   size: 200,
      // },
      {
        accessorKey: "projectNumber",
        header: "Job",
        size: 100,
        cell: ({ row }) => {
          const projectNumber = row.original?.projectNumber || "";
          const jobNumber = row.original?.jobNumber || "";
          return projectNumber && jobNumber
            ? `${projectNumber} - ${jobNumber}`
            : projectNumber || jobNumber;
        },
      },
      {
        accessorKey: "closeDate",
        header: "Close Date",
        size: 120,
        cell: ({ row }) => {
          return moment(row.original.closeDate).isSame(moment("0001-01-01"))
            ? ""
            : moment(row.original.closeDate).format("MM/DD/YYYY");
        },
      },
      {
        accessorKey: "statusTypeId",
        header: "Status",
        size: 240,
        cell: ({ row }) =>
          formatStatus(row.original.statusTypeId, row.original),
      },
      {
        accessorKey: "builders",
        header: "Builders",
        size: 80,
        cell: ({ row }) => (
          <AvatarGroup
            max={2}
            sx={{
              "& .MuiAvatar-root": {
                width: 28,
                height: 28,
                fontSize: 15,
                bgcolor: "#003057",
              },
            }}
          >
            {row.original?.builders?.map((builder: BuilderInfo) => (
              <Tooltip key={builder.builderName} title={builder.builderName}>
                <Avatar {...stringAvatar(builder.builderName)} />
              </Tooltip>
            ))}
          </AvatarGroup>
        ),
      },
      {
        id: "actions",
        header: () => <div className="text-right">Actions</div>,
        size: 160,
        cell: ({ row }) => (
          <Stack
            direction="row"
            spacing={0.5}
            justifyContent="flex-end"
            onClick={(e) => e.stopPropagation()}
            sx={{
              position: "sticky",
              right: 0,
              background: "white",
              zIndex: 10,
              boxShadow: "-8px 0 10px -4px rgba(0,0,0,0.2)",
              //borderLeft: "2px solid #e0e0e0",
              padding: "4px 8px",
              height: "100%",
              alignItems: "center",
              minWidth: 160,
              backgroundImage:
                "linear-gradient(to right, rgba(240,240,240,0.5) 0%, rgba(255,255,255,1) 15%)",
            }}
          >
            {role?.trim() === "Uploader" && (
              <Tooltip title="View Files">
                <IconButton href={`/upload?job=${row.original.jobId}`}>
                  <EyeIcon className="h-5 w-5 text-primary-600" />
                </IconButton>
              </Tooltip>
            )}
            {(row.original?.statusTypeId === 6 ||
              row.original?.statusTypeId === 9 ||
              row.original?.statusTypeId === 10) && (
              <>
                {role?.trim() === "Ops" &&
                  row.original?.biM360UploadIssue === true && (
                    <>
                      <Tooltip title="The BIM Construction Folder upload encountered an error. Please manually upload the file. Upon completion, the system will automatically update this status.">
                        <Chip
                          label="Upload Issue"
                          size="small"
                          color="error"
                          variant="outlined"
                          onClick={() => handleChipClick(row.original.jobId)}
                          clickable
                        />
                      </Tooltip>
                    </>
                  )}
                {bonusType === "ClosingBonus" && (
                  <>
                    <Tooltip title="Download Construction Folder">
                      <IconButton
                        onClick={() =>
                          downloadBuilderFolder(
                            row.original.jobId,
                            row.original
                          )
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="h-5 w-5 text-primary-600"
                        >
                          <path
                            fillRule="evenodd"
                            d="M19.5 21a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3h-5.379a.75.75 0 0 1-.53-.22L11.47 3.66A2.25 2.25 0 0 0 9.879 3H4.5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h15Zm-6.75-10.5a.75.75 0 0 0-1.5 0v4.19l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V10.5Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Download Selection Sheet">
                      <IconButton
                        onClick={() =>
                          downloadSelectionSheet(
                            row.original.jobId,
                            row.original
                          )
                        }
                      >
                        <DocumentTextIcon className="h-5 w-5 text-primary-600" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </>
            )}
            <BuilderSplitModal jobId={row.original.jobId} />
            {role?.trim() === "Payroll" && (
              <PayrollModal jobId={row.original.jobId as number} />
            )}
            {role?.trim() !== "Payroll" && role?.trim() !== "Uploader" && (
              <>
                {row.original.statusTypeId === 6 ? (
                  <PayrollModal jobId={row.original.jobId as number} />
                ) : (
                  <ReviewerViewModal jobId={row.original.jobId as number} />
                )}
              </>
            )}
          </Stack>
        ),
      },
    ],
    [role, expandedRows]
  );

  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: globalFilter,
    state: {
      globalFilter: searchTerm,
    },
    filterFns: {
      global: globalFilter,
    },
  });

  const toggleRowExpansion = (jobId: number) => {
    setExpandedRows((prev) => {
      const next = new Set(prev);
      if (next.has(jobId)) {
        next.delete(jobId);
      } else {
        next.add(jobId);
      }
      return next;
    });
  };

  const renderDetailPanel = (row: BonusProps) => {
    const isDefaultDate = (date: string) =>
      moment(date).isSame(moment("0001-01-01"));

    return (
      <div className="px-4 py-3 bg-gray-50 border-t border-b border-gray-200">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {/* PM Review Section */}
          <div className="bg-white p-3 rounded-lg shadow-sm">
            <h3 className="font-semibold text-sm mb-2 text-gray-900">
              PM Review
            </h3>
            <div className="space-y-1 text-sm">
              <div>
                <span className="text-gray-500">Reviewer:</span>{" "}
                {row.pmReviewer ? row.pmReviewer.split("@")[0] : "N/A"}
              </div>
              <div>
                <span className="text-gray-500">Review Date:</span>{" "}
                {!isDefaultDate(row.pmReviewedOn)
                  ? moment(row.pmReviewedOn).format("MM/DD/YYYY h:mm A")
                  : "N/A"}
              </div>
            </div>
          </div>

          {/* SPM Review Section */}
          <div className="bg-white p-3 rounded-lg shadow-sm">
            <h3 className="font-semibold text-sm mb-2 text-gray-900">
              SPM Review
            </h3>
            <div className="space-y-1 text-sm">
              <div>
                <span className="text-gray-500">Reviewer:</span>{" "}
                {row.spmReviewer ? row.spmReviewer.split("@")[0] : "N/A"}
              </div>
              <div>
                <span className="text-gray-500">Review Date:</span>{" "}
                {!isDefaultDate(row.spmReviewedOn)
                  ? moment(row.spmReviewedOn).format("MM/DD/YYYY h:mm A")
                  : "N/A"}
              </div>
            </div>
          </div>

          {/* DM Review Section */}
          <div className="bg-white p-3 rounded-lg shadow-sm">
            <h3 className="font-semibold text-sm mb-2 text-gray-900">
              DM Review
            </h3>
            <div className="space-y-1 text-sm">
              <div>
                <span className="text-gray-500">Reviewer:</span>{" "}
                {row.dmReviewer ? row.dmReviewer.split("@")[0] : "N/A"}
              </div>
              <div>
                <span className="text-gray-500">Review Date:</span>{" "}
                {!isDefaultDate(row.dmReviewedOn)
                  ? moment(row.dmReviewedOn).format("MM/DD/YYYY h:mm A")
                  : "N/A"}
              </div>
            </div>
          </div>

          {/* Ops Review Section */}
          {bonusType === "ClosingBonus" && (
            <div className="bg-white p-3 rounded-lg shadow-sm">
              <h3 className="font-semibold text-sm mb-2 text-gray-900">
                Ops Review
              </h3>
              <div className="space-y-1 text-sm">
                <div>
                  <span className="text-gray-500">Reviewer:</span>{" "}
                  {row.opsReviewer ? row.opsReviewer.split("@")[0] : "N/A"}
                </div>
                <div>
                  <span className="text-gray-500">Review Date:</span>{" "}
                  {!isDefaultDate(row.opsReviewedOn)
                    ? moment(row.opsReviewedOn).format("MM/DD/YYYY h:mm A")
                    : "N/A"}
                </div>
              </div>
            </div>
          )}

          {/* Payroll Section */}
          <div className="bg-white p-3 rounded-lg shadow-sm">
            <h3 className="font-semibold text-sm mb-2 text-gray-900">
              Payroll
            </h3>
            <div className="space-y-1 text-sm">
              <div>
                <span className="text-gray-500">Processed Date:</span>{" "}
                {!isDefaultDate(row.payrollProcessedOn)
                  ? moment(row.payrollProcessedOn).format("MM/DD/YYYY h:mm A")
                  : "N/A"}
              </div>
              <div>
                <span className="text-gray-500">Paid Date:</span>{" "}
                {!isDefaultDate(row.payrollPaidOn)
                  ? moment(row.payrollPaidOn).format("MM/DD/YYYY")
                  : "N/A"}
              </div>
            </div>
          </div>

          {/* Uploaded By Section */}
          <div className="bg-white p-3 rounded-lg shadow-sm">
            <h3 className="font-semibold text-sm mb-2 text-gray-900">
              Uploaded By
            </h3>
            <div className="space-y-1 text-sm">
              <div>
                <span className="text-gray-500">Updated By:</span>{" "}
                {row.updatedBy ? row.updatedBy.split("@")[0] : "N/A"}
              </div>
              <div>
                <span className="text-gray-500">Updated On:</span>{" "}
                {!isDefaultDate(row.updatedOn)
                  ? moment(row.updatedOn).format("MM/DD/YYYY h:mm A")
                  : "N/A"}
              </div>
            </div>
          </div>

          {/* Comments Section */}
          <div className="col-span-full bg-white p-3 rounded-lg shadow-sm">
            <h3 className="font-semibold text-sm mb-2 text-gray-900">
              Review Comments
            </h3>
            <div className="space-y-2">
              {row.comments && row.comments.length > 0 ? (
                row.comments.map((comment) => (
                  <div
                    key={comment.id}
                    className="border-l-2 border-gray-200 pl-3 text-sm"
                  >
                    <div className="font-medium text-gray-900">
                      {comment.createdBy.split("@")[0]}
                    </div>
                    <div className="text-xs text-gray-500">
                      {moment(comment.createdOn).format("MM/DD/YYYY h:mm A")}
                    </div>
                    <div className="mt-1 text-gray-600">{comment.comment}</div>
                    {comment.fileAttachmentName && (
                      <div className="mt-1 text-blue-600">
                        Attachment: {comment.fileAttachmentName}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="text-sm text-gray-500 italic">
                  No comments available
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Loading state
  if (loading) {
    return (
      <div className="flex justify-center items-center h-full min-h-[400px]">
        <CircularProgress />
      </div>
    );
  }

  // Empty state
  if (!filteredData.length) {
    return (
      <div className="flex justify-center items-center h-full min-h-[400px]">
        <NoRows />
      </div>
    );
  }

  // Mobile card renderer
  const renderMobileCard = (row: any) => (
    <div key={row.id} className="p-4 border-b">
      <div className="space-y-2">
        <div className="flex justify-between">
          <Tooltip title={row.original.jobAddress}>
            <span className="font-medium truncate max-w-[200px]">
              {row.original.jobAddress}
            </span>
          </Tooltip>
          <span>{row.original.jobNumber}</span>
        </div>
        <div className="grid grid-cols-2 gap-2 text-sm">
          <div>
            <span className="text-gray-500">Community:</span>{" "}
            {row.original.subdivision}
          </div>
          <div>
            <span className="text-gray-500">Region:</span>{" "}
            {row.original.regionName}
          </div>
          <div>
            <span className="text-gray-500">Project:</span>{" "}
            {row.original?.payoutInfo?.projectNumber}
          </div>
          <div>
            <span className="text-gray-500">Close Date:</span>{" "}
            {moment(row.original.closeDate).isSame(moment("0001-01-01"))
              ? ""
              : moment(row.original.closeDate).format("MM/DD/YYYY")}
          </div>
          <div className="col-span-2">
            <span className="text-gray-500">Status:</span>{" "}
            {formatStatus(row.original.statusTypeId, row.original)}
          </div>
          <div className="col-span-2">
            <span className="text-gray-500">Builders:</span>{" "}
            <AvatarGroup
              max={4}
              sx={{
                "& .MuiAvatar-root": {
                  width: 24,
                  height: 24,
                  fontSize: 13,
                  bgcolor: "#003057",
                },
              }}
            >
              {row.original?.builders?.map((builder: BuilderInfo) => (
                <Tooltip key={builder.builderName} title={builder.builderName}>
                  <Avatar {...stringAvatar(builder.builderName)} />
                </Tooltip>
              ))}
            </AvatarGroup>
          </div>
        </div>
        {/* Actions */}
        <div className="flex justify-end space-x-2 mt-4">
          <Stack direction="row" spacing={0.5} justifyContent="flex-end">
            {role?.trim() === "Uploader" && (
              <Tooltip title="View Files">
                <IconButton href={`/upload?job=${row.original.jobId}`}>
                  <EyeIcon className="h-5 w-5 text-primary-600" />
                </IconButton>
              </Tooltip>
            )}
            {(row.original?.statusTypeId === 6 ||
              row.original?.statusTypeId === 9 ||
              row.original?.statusTypeId === 10) && (
              <>
                {role?.trim() === "Ops" &&
                  row.original?.biM360UploadIssue === true && (
                    <Tooltip title="The BIM Construction Folder upload encountered an error. Please manually upload the file. Upon completion, the system will automatically update this status.">
                      <Chip
                        label="Upload Issue"
                        size="small"
                        color="error"
                        variant="outlined"
                        onClick={() => handleChipClick(row.original.jobId)}
                        clickable
                      />
                    </Tooltip>
                  )}
                <Tooltip title="Download Construction Folder">
                  <IconButton
                    onClick={() =>
                      downloadBuilderFolder(row.original.jobId, row.original)
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5 w-5 text-primary-600"
                    >
                      <path
                        fillRule="evenodd"
                        d="M19.5 21a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3h-5.379a.75.75 0 0 1-.53-.22L11.47 3.66A2.25 2.25 0 0 0 9.879 3H4.5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h15Zm-6.75-10.5a.75.75 0 0 0-1.5 0v4.19l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V10.5Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Download Selection Sheet">
                  <IconButton
                    onClick={() =>
                      downloadSelectionSheet(row.original.jobId, row.original)
                    }
                  >
                    <DocumentTextIcon className="h-5 w-5 text-primary-600" />
                  </IconButton>
                </Tooltip>
              </>
            )}
            <BuilderSplitModal jobId={row.original.jobId} />
            {role?.trim() === "Payroll" && (
              <PayrollModal jobId={row.original.jobId as number} />
            )}
            {role?.trim() !== "Payroll" && role?.trim() !== "Uploader" && (
              <>
                {row.original.statusTypeId === 6 ? (
                  <PayrollModal jobId={row.original.jobId as number} />
                ) : (
                  <ReviewerViewModal jobId={row.original.jobId as number} />
                )}
              </>
            )}
          </Stack>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="overflow-x-auto h-full">
        {/* Desktop view */}
        <div className="hidden md:block">
          <table className="min-w-full divide-y divide-gray-200 table-fixed">
            <thead className="bg-gray-50 sticky top-0 z-10">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                        header.id === "actions"
                          ? "sticky right-0 bg-gray-50 z-20 shadow-md border-l-2 border-gray-300"
                          : ""
                      }`}
                      style={{
                        width: header.getSize(),
                        minWidth: header.getSize(),
                        ...(header.id === "actions"
                          ? {
                              backgroundImage:
                                "linear-gradient(to right, rgba(240,240,240,0.5) 0%, rgba(249,250,251,1) 15%)",
                            }
                          : {}),
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {table.getRowModel().rows.map((row) => (
                <>
                  <tr
                    key={row.original.jobId}
                    className="hover:bg-gray-50 cursor-pointer"
                    onClick={() => toggleRowExpansion(row.original.jobId)}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className={`px-6 py-4 whitespace-nowrap text-sm text-gray-500 ${
                          cell.column.id === "actions"
                            ? "sticky right-0 bg-white z-10 shadow-md border-l-2 border-gray-300"
                            : ""
                        } ${
                          expandedRows.has(row.original.jobId) &&
                          cell.column.id === "actions"
                            ? "bg-gray-50"
                            : ""
                        }`}
                        style={
                          cell.column.id === "actions"
                            ? {
                                backgroundImage:
                                  "linear-gradient(to right, rgba(240,240,240,0.5) 0%, rgba(255,255,255,1) 15%)",
                              }
                            : {}
                        }
                      >
                        {cell.column.id === "expand" ? (
                          expandedRows.has(row.original.jobId) ? (
                            <ExpandLess className="text-gray-400" />
                          ) : (
                            <ExpandMore className="text-gray-400" />
                          )
                        ) : (
                          flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )
                        )}
                      </td>
                    ))}
                  </tr>
                  {expandedRows.has(row.original.jobId) && (
                    <tr>
                      <td colSpan={columns.length}>
                        {renderDetailPanel(row.original)}
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>

        {/* Mobile view */}
        <div className="block md:hidden">
          {table.getRowModel().rows.map((row) => renderMobileCard(row))}
        </div>
      </div>

      {/* BIM Upload Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        hideBackdrop
        sx={{
          boxShadow: "none",
        }}
        PaperProps={{
          sx: {
            boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.3)",
            border: "1px solid #e0e0e0",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>Confirm Manual Upload</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please manually upload your construction folder to BIM. Once you
            have completed the upload, click "Confirm" to acknowledge the
            submission.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmUpload}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(ReviewTable);
