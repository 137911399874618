import React, { useState, useRef, useEffect } from "react";
import { useData } from "../../providers/DataProvider";
import { useNavigate } from "react-router-dom";

const BonusTypeSwitcher: React.FC = () => {
  const { bonusType, setBonusType, role } = useData();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Define the restricted roles that should only see ClosingBonus
  const restrictedRoles = ["Ops", "OpsPlus", "Uploader"];
  
  // Check if the current role is restricted (with null check)
  const isRestrictedRole = role ? restrictedRoles.includes(role) : false;

  // Define bonus types based on role
  const bonusTypes = isRestrictedRole 
    ? [{ label: "Closing Bonus", value: "ClosingBonus" }]
    : [
        { label: "Closing Bonus", value: "ClosingBonus" },
        { label: "Stage 5 Bonus", value: "Stage5Bonus" },
      ];

  const getCurrentLabel = () => {
    return (
      bonusTypes.find((type) => type.value === bonusType)?.label ||
      "Closing Bonus"
    );
  };

  // Force bonusType to ClosingBonus for restricted roles
  useEffect(() => {
    if (isRestrictedRole && bonusType !== "ClosingBonus") {
      setBonusType("ClosingBonus");
      navigate("/");
    }
  }, [role, bonusType, isRestrictedRole, setBonusType, navigate]);

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`flex items-center justify-center bg-primary-600 hover:bg-primary-700 text-white font-medium py-2 px-4 rounded-md transition duration-150 ease-in-out ${
          isRestrictedRole ? "cursor-default" : ""
        }`}
        disabled={isRestrictedRole}
      >
        <span className="mr-2">{getCurrentLabel()}</span>
        {!isRestrictedRole && (
          <svg
            className={`w-4 h-4 transition-transform duration-200 ${
              isOpen ? "transform rotate-180" : ""
            }`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        )}
      </button>
      {isOpen && !isRestrictedRole && (
        <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
          {bonusTypes.map((type, index) => (
            <button
              key={index}
              onClick={() => {
                setBonusType(type.value);
                setIsOpen(false);
                navigate("/");
              }}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left transition duration-150 ease-in-out"
            >
              {type.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default BonusTypeSwitcher;