/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect, useMemo } from "react";
import { useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";
import {
  MagnifyingGlassIcon,
  ChevronUpDownIcon,
  DocumentTextIcon,
  ClockIcon,
  CheckCircleIcon,
  XCircleIcon,
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  CurrencyDollarIcon,
  ExclamationTriangleIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import AxiosInstance from "../../utils/axiosInstance";
import FilterModal from "../../modals/AdvancedFilters";
import { useData } from "../../providers/DataProvider";
import moment from "moment";
import { useClickOutside } from "../../hooks/useClickOutside";
import {
  AvatarGroup,
  Avatar,
  Tooltip,
  CircularProgress,
  Chip,
} from "@mui/material";
import { useSearchParams, useNavigate } from "react-router-dom";
import Fuse from "fuse.js";
import { useInView } from "react-intersection-observer";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

interface BonusResult {
  bonusId: number;
  jobId: number;
  statusTypeId: number;
  jobNumber: string;
  projectNumber: string;
  jobAddress: string;
  subdivision: string;
  regionName: string;
  company: string;
  closeDate: string;
  year: number;
  month: number;
  comments: {
    id: number;
    jobId: number;
    roleTypeId: number;
    action: string;
    comment: string;
    createdOn: string;
    createdBy: string;
    fileAttachmentName: string;
    fileAttachmentTypeName: string;
  }[];
  builders: any[];
  payrollProcessedOn: string;
  payrollPaidOn: string;
  pmReviewedOn: string;
  opsReviewedOn: string;
  createdOn: string;
  createdBy: string;
  updatedOn: string;
  updatedBy: string;
  biM360UploadIssue: boolean;
  pmReviewer: string;
  opsReviewer: string;
  spmReviewer: string;
  spmReviewedOn: string;
  dmReviewer: string;
  dmReviewedOn: string;
}

interface BonusStatistics {
  totalJobs: number;
  uploadingRejected: number;
  pendingReview: number;
  readyToUpload: number;
  uploading: number;
  inReview: number;
  opsApproved: number;
  payrollInProgress: number;
  paid: number;
  inUploadAfterRejection: number;
  busted: number;
}

interface ApiResponse {
  bonusDetails: BonusResult[];
  bonusStatistics: BonusStatistics;
}

interface UserRoles {
  roles: string[];
  hasRole: (role: string) => boolean;
}

const statuses: { [key: number]: string } = {
  0: "text-gray-600 bg-gray-100",
  1: "text-gray-700 bg-gray-100",
  2: "text-yellow-700 bg-yellow-100",
  3: "text-blue-700 bg-blue-100",
  4: "text-purple-700 bg-purple-100",
  5: "text-indigo-700 bg-indigo-100",
  6: "text-green-700 bg-green-100",
  7: "text-red-700 bg-red-100",
  8: "text-orange-700 bg-orange-100",
  9: "text-teal-700 bg-teal-100",
  10: "text-pink-700 bg-pink-100",
};

const sortOptions = [
  { name: "Close Date", value: "CloseDate" },
  { name: "Subdivision", value: "Subdivision" },
  { name: "Status", value: "JobStatus" },
];

const dateRangeOptions = [
  { name: "All Time", value: 0 },
  { name: "Last 30 Days", value: 30 },
  { name: "Last 60 Days", value: 60 },
  { name: "Last 90 Days", value: 90 },
];

const formatStatus = (status: number, bonus?: BonusResult): string => {
  const isDefaultDate = (date: string | Date) =>
    moment(date).isSame(moment("0001-01-01"));

  switch (status) {
    case 0:
      return "Not Started";
    case 1:
      return "In Upload Process";
    case 2:
      return "PM Review";
    case 3:
      return "SPM Review";
    case 4:
      return "DM Review";
    case 5:
      return "Ops Review";
    case 6:
      return "Ops Approved";
    case 7:
      return "Rejected";
    case 8:
      return "Busted";
    case 9:
      return bonus?.payrollProcessedOn &&
        !isDefaultDate(bonus.payrollProcessedOn)
        ? `In Payroll Process since ${moment(bonus.payrollProcessedOn).format(
            "LL"
          )}`
        : "In Payroll Process";
    case 10:
      return bonus?.payrollPaidOn && !isDefaultDate(bonus.payrollPaidOn)
        ? `Paid on ${moment(bonus.payrollPaidOn).format("LL")}`
        : "Paid";
    default:
      return "";
  }
};

const SortDropdown: React.FC<{
  sortBy: string;
  setSortBy: (value: string) => void;
}> = ({ sortBy, setSortBy }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useClickOutside(() => setIsOpen(false));

  return (
    <div ref={ref} className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => setIsOpen(!isOpen)}
        >
          Sort by: {sortOptions.find((option) => option.value === sortBy)?.name}
          <ChevronUpDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </button>
      </div>

      {isOpen && (
        <div className="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1 max-h-60 overflow-auto">
            {sortOptions.map((option) => (
              <a
                key={option.value}
                href="#"
                className={`${
                  option.value === sortBy
                    ? "bg-gray-100 text-gray-900"
                    : "text-gray-700"
                } block px-4 py-2 text-sm hover:bg-gray-50 transition duration-150 ease-in-out`}
                onClick={(e) => {
                  e.preventDefault();
                  setSortBy(option.value);
                  setIsOpen(false);
                }}
              >
                {option.name}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const CommunityDropdown: React.FC<{
  selectedCommunities: string[];
  onChange: (value: string[]) => void;
  onClear: () => void;
  communities: any[];
}> = ({ selectedCommunities, onChange, onClear, communities }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useClickOutside(() => setIsOpen(false));

  const handleToggleCommunity = (communityId: string) => {
    const newSelection = selectedCommunities.includes(communityId)
      ? selectedCommunities.filter((id) => id !== communityId)
      : [...selectedCommunities, communityId];
    onChange(newSelection);
  };

  const handleClearAll = (e: React.MouseEvent) => {
    e.preventDefault();
    onChange([]);
    setIsOpen(false);
    localStorage.removeItem("selectedCommunities");
    localStorage.removeItem("allBonusesCommunities");
    onClear();
  };

  const getDisplayText = () => {
    if (selectedCommunities.length === 0) return "Select Communities";
    if (selectedCommunities.length === 1) {
      return communities.find(
        (c) => c.projectId.toString() === selectedCommunities[0]
      )?.subDivision;
    }
    return `${selectedCommunities.length} communities selected`;
  };

  return (
    <div ref={ref} className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => setIsOpen(!isOpen)}
        >
          {getDisplayText()}
          <ChevronUpDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </button>
      </div>

      {isOpen && (
        <div className="absolute left-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="p-2 border-b border-gray-200">
            <button
              className="w-full px-4 py-2 text-sm text-red-600 hover:bg-red-50 rounded-md transition duration-150 ease-in-out"
              onClick={handleClearAll}
            >
              Clear All
            </button>
          </div>
          <div className="py-1 max-h-60 overflow-auto">
            {communities.map((community) => (
              <div
                key={community.projectId}
                className="flex items-center px-4 py-2 hover:bg-gray-50"
              >
                <input
                  type="checkbox"
                  id={`community-${community.projectId}`}
                  checked={selectedCommunities.includes(
                    community.projectId.toString()
                  )}
                  onChange={() =>
                    handleToggleCommunity(community.projectId.toString())
                  }
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label
                  htmlFor={`community-${community.projectId}`}
                  className="ml-3 text-sm text-gray-700 cursor-pointer"
                >
                  {community.subDivision}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const DateRangeDropdown: React.FC<{
  selectedRange: number;
  setSelectedRange: (value: number) => void;
}> = ({ selectedRange, setSelectedRange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useClickOutside(() => setIsOpen(false));

  return (
    <div ref={ref} className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => setIsOpen(!isOpen)}
        >
          {
            dateRangeOptions.find((option) => option.value === selectedRange)
              ?.name
          }
          <ChevronUpDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </button>
      </div>

      {isOpen && (
        <div className="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1 max-h-60 overflow-auto">
            {dateRangeOptions.map((option) => (
              <a
                key={option.value}
                href="#"
                className={`${
                  option.value === selectedRange
                    ? "bg-gray-100 text-gray-900"
                    : "text-gray-700"
                } block px-4 py-2 text-sm hover:bg-gray-50 transition duration-150 ease-in-out`}
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedRange(option.value);
                  setIsOpen(false);
                }}
              >
                {option.name}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const stringAvatar = (name: string) => ({
  sx: {
    width: 24,
    height: 24,
    bgcolor: "#003057",
    fontSize: 12,
  },
  children: `${name?.split(" ")[0]?.[0] || ""}${
    name?.split(" ")[1]?.[0] || ""
  }`,
});

const getLastReviewedDate = (bonus: BonusResult): string => {
  const dates = [
    bonus.pmReviewedOn,
    bonus.opsReviewedOn,
    bonus.payrollProcessedOn,
    bonus.payrollPaidOn,
  ].filter((date) => date && date !== "0001-01-01T00:00:00");

  if (dates.length === 0) return "Not reviewed";
  return `Last reviewed: ${moment(
    Math.max(...dates.map((d) => new Date(d).getTime()))
  ).format("LL")}`;
};

const secondaryNavigation = [
  { name: "My Bonuses", href: "#", current: true },
  { name: "My Community", href: "#", current: false },
  { name: "All Bonuses", href: "#", current: false },
];

const DetailPanel: React.FC<{ bonus: BonusResult }> = ({ bonus }) => {
  const isDefaultDate = (date: string) =>
    moment(date).isSame(moment("0001-01-01"));

  return (
    <div className="px-4 py-3 bg-gray-50 border-t border-b border-gray-200">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {/* Last Update Section */}
        <div className="bg-white p-3 rounded-lg shadow-sm">
          <h3 className="font-semibold text-sm mb-2 text-gray-900">
            Uploaded By
          </h3>
          <div className="space-y-1 text-sm">
            <div>
              <span className="text-gray-500">Updated By:</span>{" "}
              {bonus.updatedBy ? bonus.updatedBy.split("@")[0] : "N/A"}
            </div>
            <div>
              <span className="text-gray-500">Updated On:</span>{" "}
              {!isDefaultDate(bonus.updatedOn)
                ? moment(bonus.updatedOn).format("MM/DD/YYYY h:mm A")
                : "N/A"}
            </div>
          </div>
        </div>

        {/* PM Review Section */}
        <div className="bg-white p-3 rounded-lg shadow-sm">
          <h3 className="font-semibold text-sm mb-2 text-gray-900">
            PM Review
          </h3>
          <div className="space-y-1 text-sm">
            <div>
              <span className="text-gray-500">Reviewer:</span>{" "}
              {bonus.pmReviewer ? bonus.pmReviewer.split("@")[0] : "N/A"}
            </div>
            <div>
              <span className="text-gray-500">Review Date:</span>{" "}
              {!isDefaultDate(bonus.pmReviewedOn)
                ? moment(bonus.pmReviewedOn).format("MM/DD/YYYY h:mm A")
                : "N/A"}
            </div>
          </div>
        </div>

        {/* DM Review Section */}
        <div className="bg-white p-3 rounded-lg shadow-sm">
          <h3 className="font-semibold text-sm mb-2 text-gray-900">
            DM Review
          </h3>
          <div className="space-y-1 text-sm">
            <div>
              <span className="text-gray-500">Reviewer:</span>{" "}
              {bonus.dmReviewer ? bonus.dmReviewer.split("@")[0] : "N/A"}
            </div>
            <div>
              <span className="text-gray-500">Review Date:</span>{" "}
              {!isDefaultDate(bonus.dmReviewedOn)
                ? moment(bonus.dmReviewedOn).format("MM/DD/YYYY h:mm A")
                : "N/A"}
            </div>
          </div>
        </div>

        {/* SPM Review Section */}
        <div className="bg-white p-3 rounded-lg shadow-sm">
          <h3 className="font-semibold text-sm mb-2 text-gray-900">
            SPM Review
          </h3>
          <div className="space-y-1 text-sm">
            <div>
              <span className="text-gray-500">Reviewer:</span>{" "}
              {bonus.spmReviewer ? bonus.spmReviewer.split("@")[0] : "N/A"}
            </div>
            <div>
              <span className="text-gray-500">Review Date:</span>{" "}
              {!isDefaultDate(bonus.spmReviewedOn)
                ? moment(bonus.spmReviewedOn).format("MM/DD/YYYY h:mm A")
                : "N/A"}
            </div>
          </div>
        </div>

        {/* Ops Review Section */}
        <div className="bg-white p-3 rounded-lg shadow-sm">
          <h3 className="font-semibold text-sm mb-2 text-gray-900">
            Ops Review
          </h3>
          <div className="space-y-1 text-sm">
            <div>
              <span className="text-gray-500">Reviewer:</span>{" "}
              {bonus.opsReviewer ? bonus.opsReviewer.split("@")[0] : "N/A"}
            </div>
            <div>
              <span className="text-gray-500">Review Date:</span>{" "}
              {!isDefaultDate(bonus.opsReviewedOn)
                ? moment(bonus.opsReviewedOn).format("MM/DD/YYYY h:mm A")
                : "N/A"}
            </div>
          </div>
        </div>

        {/* Payroll Section */}
        <div className="bg-white p-3 rounded-lg shadow-sm">
          <h3 className="font-semibold text-sm mb-2 text-gray-900">Payroll</h3>
          <div className="space-y-1 text-sm">
            <div>
              <span className="text-gray-500">Processed Date:</span>{" "}
              {!isDefaultDate(bonus.payrollProcessedOn)
                ? moment(bonus.payrollProcessedOn).format("MM/DD/YYYY h:mm A")
                : "N/A"}
            </div>
            <div>
              <span className="text-gray-500">Paid Date:</span>{" "}
              {!isDefaultDate(bonus.payrollPaidOn)
                ? moment(bonus.payrollPaidOn).format("MM/DD/YYYY")
                : "N/A"}
            </div>
          </div>
        </div>

        {/* Comments Section */}
        <div className="col-span-full bg-white p-3 rounded-lg shadow-sm">
          <h3 className="font-semibold text-sm mb-2 text-gray-900">
            Review Comments
          </h3>
          <div className="space-y-2">
            {bonus.comments && bonus.comments.length > 0 ? (
              bonus.comments.map((comment) => (
                <div
                  key={comment.id}
                  className="border-l-2 border-gray-200 pl-3 text-sm"
                >
                  <div className="font-medium text-gray-900">
                    {comment.createdBy.split("@")[0]}
                  </div>
                  <div className="text-xs text-gray-500">
                    {moment(comment.createdOn).format("MM/DD/YYYY h:mm A")}
                  </div>
                  <div className="mt-1 text-gray-600">{comment.comment}</div>
                  {comment.fileAttachmentName && (
                    <div className="mt-1 text-blue-600">
                      Attachment: {comment.fileAttachmentName}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="text-sm text-gray-500 italic">
                No comments available
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Home: React.FC = () => {
  const { instance, accounts } = useMsal();
  const currentAccount = accounts[0] as AccountInfo;
  const navigate = useNavigate();
  const [bonusData, setBonusData] = useState<BonusResult[]>([]);
  const [bonusStats, setBonusStats] = useState<BonusStatistics | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(
    () => localStorage.getItem("bonusListSortBy") || "CloseDate"
  );
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    address: "",
    builders: [] as string[],
    workflowUsers: [] as string[],
    jobIds: "",
  });
  const {
    communities2,
    selectedCommunity,
    setSelectedCommunity,
    role,
    selectedRegion,
  } = useData();
  const [localSelectedCommunities, setLocalSelectedCommunities] = useState<
    string[]
  >(() => JSON.parse(localStorage.getItem("selectedCommunities") || "[]"));

  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize activeTab from URL params or default to "My Bonuses"
  const [activeTab, setActiveTab] = useState(() => {
    return searchParams.get("tab") || "My Bonuses";
  });

  // Update the selectedDateRange initialization to check localStorage
  const [selectedDateRange, setSelectedDateRange] = useState(() =>
    parseInt(localStorage.getItem("bonusListDateRange") || "90", 10)
  );

  // New state for pagination
  const [totalResults, setTotalResults] = useState(0);
  const pageSize = 1000; // This should match the PageSize in the API request

  const [hasMore, setHasMore] = useState(true);
  const [pageOffset, setPageOffset] = useState(0);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  // Add a new state for storing All Bonuses tab community selection
  const [tempSelectedCommunities, setTempSelectedCommunities] = useState<
    string[]
  >(() => JSON.parse(localStorage.getItem("allBonusesCommunities") || "[]"));

  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());

  const toggleRowExpansion = (jobId: number) => {
    setExpandedRows((prev) => {
      const next = new Set(prev);
      if (next.has(jobId)) {
        next.delete(jobId);
      } else {
        next.add(jobId);
      }
      return next;
    });
  };

  useEffect(() => {
    if (localSelectedCommunities.length > 0) {
      const community = communities2.find(
        (c) => c.projectId.toString() === localSelectedCommunities[0]
      );
      if (community) {
        setSelectedCommunity(community);
      }
    }
  }, [localSelectedCommunities, communities2, setSelectedCommunity]);

  useEffect(() => {
    const userRoles = (currentAccount?.idTokenClaims?.roles as string[]) || [];
    const hasUploadAccess = userRoles.includes("Uploader");

    if (!hasUploadAccess) {
      navigate("/");
    }
  }, [currentAccount, navigate]);

  const userRoles: UserRoles = {
    roles: (currentAccount?.idTokenClaims?.roles as string[]) || [],
    hasRole: function (role: string) {
      return this.roles.includes(role);
    },
  };

  const handleTitleClick = (e: React.MouseEvent, jobId: number) => {
    e.stopPropagation(); // Prevent row expansion
    const path = userRoles.hasRole("Uploader")
      ? `/Upload/create/${jobId}`
      : `/Upload/view/${jobId}`;
    navigate(path);
  };

  const fetchBonusData = async (offset: number = pageOffset) => {
    try {
      setIsLoading(true);
      setError(null);

      let requestBody: any = {
        FilterByAddress: filters.address || null,
        FilterByBuilder: filters.builders.length > 0 ? filters.builders : null,
        FilterByWorkflowUser:
          filters.workflowUsers.length > 0 ? filters.workflowUsers[0] : null,
        FilterByJobIds: filters.jobIds
          ? filters.jobIds.split(",").map((id) => id.trim())
          : null,
        SortOrder: sortBy,
        PageOffset: offset,
        PageSize: pageSize,
        FilterByRegions: selectedRegion ? [selectedRegion.regionName] : null,
      };

      // Handle subdivisions based on active tab
      if (activeTab === "All Bonuses") {
        requestBody.FilterBySubdivisions =
          tempSelectedCommunities.length > 0
            ? tempSelectedCommunities
                .map(
                  (id) =>
                    communities2.find((c) => c.projectId.toString() === id)
                      ?.subDivision
                )
                .filter(Boolean)
            : null;
      } else if (activeTab === "My Community") {
        // First check localSelectedCommunities, then fall back to selectedCommunity from context
        requestBody.FilterBySubdivisions =
          localSelectedCommunities.length > 0
            ? localSelectedCommunities
                .map(
                  (id) =>
                    communities2.find((c) => c.projectId.toString() === id)
                      ?.subDivision
                )
                .filter(Boolean)
            : selectedCommunity
            ? [selectedCommunity.subDivision]
            : null;
      }

      // Add date range filters if not "All Time"
      if (selectedDateRange !== 0) {
        requestBody.FilterByClosingDateRangeStartDate = moment()
          .subtract(selectedDateRange, "days")
          .startOf("day")
          .toISOString();
        requestBody.FilterByClosingDateRangeEndDate = moment()
          .endOf("day")
          .toISOString();
      }

      // Apply user filter for My Bonuses tab
      if (activeTab === "My Bonuses") {
        requestBody.FilterByWorkflowUser = currentAccount.username;
      }

      const response = await AxiosInstance.post<ApiResponse>(
        "/api/cba/bonus/search",
        requestBody,
        {
          headers: {
            RoleType: role || "",
          },
        }
      );

      if (response.data) {
        if (offset === 0) {
          setBonusData(response.data.bonusDetails || []);
        } else {
          setBonusData((prevData) => [
            ...prevData,
            ...(response.data.bonusDetails || []),
          ]);
        }
        setBonusStats(response.data.bonusStatistics);
        setTotalResults(response.data.bonusStatistics.totalJobs);
        setHasMore(response.data.bonusDetails.length === pageSize);
        setError(null);
      } else {
        setError("Unexpected response format. Please try again later.");
        if (offset === 0) {
          setBonusData([]);
        }
        setBonusStats(null);
        setTotalResults(0);
        setHasMore(false);
      }
    } catch (err) {
      console.error("Error fetching bonus data:", err);
      setError("Failed to load bonus data. Please try again later.");
      if (offset === 0) {
        setBonusData([]);
      }
      setBonusStats(null);
      setTotalResults(0);
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      selectedRegion &&
      (selectedCommunity ||
        activeTab !== "My Community" ||
        tempSelectedCommunities.length > 0 ||
        localSelectedCommunities.length > 0)
    ) {
      fetchBonusData(0);
      setPageOffset(0);
    } else {
      setBonusData([]);
      setError(null);
      setTotalResults(0);
      setHasMore(false);
    }
  }, [
    selectedRegion,
    selectedCommunity,
    tempSelectedCommunities,
    localSelectedCommunities,
    sortBy,
    role,
    activeTab,
    currentAccount.username,
    selectedDateRange,
    communities2,
    searchParams,
    filters,
  ]);

  useEffect(() => {
    if (inView && hasMore && !isLoading) {
      setPageOffset((prevOffset) => prevOffset + pageSize);
      fetchBonusData(pageOffset + pageSize);
    }
  }, [inView, hasMore, isLoading]);

  const fuse = useMemo(() => {
    if (bonusData.length === 0) return null;
    const options = {
      keys: ["jobNumber", "projectNumber", "jobAddress", "subdivision"],
      threshold: 0.3,
    };
    return new Fuse(bonusData, options);
  }, [bonusData]);

  const filteredBonuses = useMemo(() => {
    let filtered = bonusData;

    // // Filter out bonuses with a close date of "0001-01-01T00:00:00"
    // filtered = filtered.filter(
    //   (bonus) => bonus.closeDate !== "0001-01-01T00:00:00"
    // );

    if (!searchTerm || !fuse) return filtered;
    return fuse.search(searchTerm).map((result) => result.item);
  }, [searchTerm, fuse, bonusData]);

  const handleApplyFilters = (newFilters: typeof filters) => {
    setFilters(newFilters);
    fetchBonusData(0); // Reset to first page when applying new filters
    setPageOffset(0);
  };

  // Update the groupBonusesByDate function
  const groupBonusesByDate = (bonuses: BonusResult[]) => {
    if (!Array.isArray(bonuses)) {
      console.warn("groupBonusesByDate received non-array input:", bonuses);
      return { rejected: [], byDate: {} };
    }

    // First, separate rejected bonuses
    const rejected = bonuses.filter((bonus) => bonus.statusTypeId === 7);
    const nonRejected = bonuses.filter((bonus) => bonus.statusTypeId !== 7);

    // Then group the non-rejected bonuses by date
    const byDate = nonRejected.reduce((acc, bonus) => {
      const date = moment(
        bonus.pmReviewedOn ||
          bonus.opsReviewedOn ||
          bonus.payrollProcessedOn ||
          bonus.payrollPaidOn
      ).format("YYYY-MM-DD");
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(bonus);
      return acc;
    }, {} as Record<string, BonusResult[]>);

    return { rejected, byDate };
  };

  // Helper function to format date headers
  const formatDateHeader = (date: string) => {
    // Check for default/invalid date (January 1, 0001)
    if (date === "0001-01-01") {
      return "";
    }

    const today = moment().format("YYYY-MM-DD");
    const yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");

    if (date === today) {
      return "Today";
    } else if (date === yesterday) {
      return "Yesterday";
    } else {
      return moment(date).format("MMMM D, YYYY");
    }
  };

  // Update the stats section with categories and subcategories
  const statsCategories = [
    {
      name: "Overview",
      stats: [
        {
          name: "Total Bonuses",
          value: bonusStats?.totalJobs.toString() || "0",
          icon: DocumentTextIcon,
          color: "text-blue-600",
        },
      ],
    },
    {
      name: "In Progress",
      stats: [
        {
          name: "Ready to Upload",
          value: bonusStats?.readyToUpload.toString() || "0",
          icon: ClockIcon,
          color: "text-amber-600",
          tooltip: "Bonuses ready for upload",
        },
        {
          name: "Uploading",
          value: bonusStats?.uploading.toString() || "0",
          icon: ArrowUpTrayIcon,
          color: "text-purple-600",
          tooltip: "Currently being uploaded",
        },
      ],
    },
    {
      name: "Review Status",
      stats: [
        {
          name: "In Review",
          value: bonusStats?.inReview.toString() || "0",
          icon: MagnifyingGlassIcon,
          color: "text-indigo-600",
          tooltip: "Under initial review",
        },
        {
          name: "Ops Approved",
          value: bonusStats?.opsApproved.toString() || "0",
          icon: CheckCircleIcon,
          color: "text-green-600",
          tooltip: "Approved by operations",
        },
        {
          name: "Payroll Processing",
          value: bonusStats?.payrollInProgress.toString() || "0",
          icon: CurrencyDollarIcon,
          color: "text-emerald-600",
          tooltip: "Being processed by payroll",
        },
      ],
    },
    {
      name: "Issues",
      stats: [
        {
          name: "Upload Rejected",
          value: bonusStats?.uploadingRejected.toString() || "0",
          icon: XCircleIcon,
          color: "text-red-600",
          tooltip: "Upload was rejected",
        },
        {
          name: "Rejected - Reupload",
          value: bonusStats?.inUploadAfterRejection.toString() || "0",
          icon: ArrowPathIcon,
          color: "text-orange-600",
          tooltip: "Rejected and pending reupload",
        },
        {
          name: "Busted",
          value: bonusStats?.busted.toString() || "0",
          icon: ExclamationTriangleIcon,
          color: "text-yellow-600",
          tooltip: "Marked as busted",
        },
      ],
    },
  ];

  // Update the handleCommunityChange function
  const handleCommunityChange = (values: string[]) => {
    if (activeTab === "All Bonuses") {
      setTempSelectedCommunities(values);
      localStorage.setItem("allBonusesCommunities", JSON.stringify(values));
    } else {
      setLocalSelectedCommunities(values);
      localStorage.setItem("selectedCommunities", JSON.stringify(values));
    }

    // Update selected communities in context if needed
    const selectedCommunityObjects = values
      .map((value) =>
        communities2.find((c) => c.projectId.toString() === value)
      )
      .filter(Boolean);
    setSelectedCommunity(selectedCommunityObjects[0] || null); // You might want to modify this based on your needs
  };

  // Update the handleTabClick function
  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
    setSearchParams({ tab: tabName });
    if (tabName === "All Bonuses") {
      // Clear filters applied by other tabs
      setFilters({
        address: "",
        builders: [] as string[],
        workflowUsers: [] as string[],
        jobIds: "",
      });
      // Restore the saved All Bonuses community selection
      const savedAllBonusesCommunity = localStorage.getItem(
        "allBonusesCommunities"
      );
      setTempSelectedCommunities(JSON.parse(savedAllBonusesCommunity || "[]"));
      const selected = savedAllBonusesCommunity
        ? communities2.find(
            (c) => c.projectId.toString() === savedAllBonusesCommunity
          )
        : null;
      setSelectedCommunity(selected || null);
    } else if (tabName === "My Community") {
      // Restore the localSelectedCommunity when switching back to My Community
      const community = communities2.find(
        (c) => c.projectId.toString() === localSelectedCommunities[0]
      );
      setSelectedCommunity(community || null);
    }
  };

  const handleExportToPDF = () => {
    const doc = new jsPDF();

    // Add title
    doc.setFontSize(18);
    doc.text("Bonus Items Report", 14, 22);

    // Add date
    doc.setFontSize(11);
    doc.text(`Generated on: ${new Date().toLocaleString()}`, 14, 30);

    const tableColumn = [
      "Job Number",
      "Project Number",
      "Address",
      "Subdivision",
      "Close Date",
      "Status",
    ];
    const tableRows = filteredBonuses.map((bonus) => [
      bonus.jobNumber,
      bonus.projectNumber,
      bonus.jobAddress,
      bonus.subdivision,
      moment(bonus.closeDate).format("MMM D, YYYY"),
      formatStatus(bonus.statusTypeId, bonus),
    ]);

    (doc as any).autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 40,
      styles: { fontSize: 8 },
      columnStyles: {
        0: { cellWidth: 20 },
        1: { cellWidth: 20 },
        2: { cellWidth: 50 },
        3: { cellWidth: 30 },
        4: { cellWidth: 25 },
        5: { cellWidth: 25 },
      },
    });

    doc.save("bonus_items_report.pdf");
  };

  // Update the stats array to be more concise
  const stats = [
    {
      name: "Total",
      value: bonusStats?.totalJobs.toString() || "0",
      icon: DocumentTextIcon,
      color: "text-blue-600",
    },
    {
      name: "Not Started",
      value: bonusStats?.readyToUpload.toString() || "0",
      icon: ClockIcon,
      color: "text-amber-600",
    },
    {
      name: "In Upload",
      value: bonusStats?.uploading.toString() || "0",
      icon: ArrowUpTrayIcon,
      color: "text-purple-600",
    },
    {
      name: "In Review",
      value: bonusStats?.inReview.toString() || "0",
      icon: MagnifyingGlassIcon,
      color: "text-indigo-600",
    },
    {
      name: "Approved",
      value: bonusStats?.opsApproved.toString() || "0",
      icon: CheckCircleIcon,
      color: "text-green-600",
    },
    {
      name: "Rejected",
      value: (
        (bonusStats?.uploadingRejected || 0) +
        (bonusStats?.inUploadAfterRejection || 0)
      ).toString(),
      icon: XCircleIcon,
      color: "text-red-600",
    },
    {
      name: "Busted",
      value: bonusStats?.busted.toString() || "0",
      icon: ExclamationTriangleIcon,
      color: "text-yellow-600",
    },
    {
      name: "Paid",
      value: bonusStats?.paid.toString() || "0",
      icon: CurrencyDollarIcon,
      color: "text-green-600",
    },
    {
      name: "In Payroll",
      value: bonusStats?.payrollInProgress.toString() || "0",
      icon: ArrowPathIcon,
      color: "text-blue-600",
    },
  ];

  // Create a new function to handle sort changes
  const handleSortChange = (newSortValue: string) => {
    setSortBy(newSortValue);
    localStorage.setItem("bonusListSortBy", newSortValue);
  };

  // Create a new function to handle date range changes
  const handleDateRangeChange = (newRange: number) => {
    setSelectedDateRange(newRange);
    localStorage.setItem("bonusListDateRange", newRange.toString());
  };

  return (
    <div className="min-h-screen flex flex-col">
      <div className="xl:pl-0 flex-grow flex flex-col">
        {/* Sticky search header */}
        <div className="top-0 z-40 flex h-20 shrink-0 items-center gap-x-6 border-b border-gray-200 bg-white px-4 sm:px-6 lg:px-8">
          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            <form className="flex flex-1" action="#" method="GET">
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <div className="relative w-full">
                <MagnifyingGlassIcon
                  className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                  aria-hidden="true"
                />
                <input
                  id="search-field"
                  className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0"
                  placeholder="Search bonuses..."
                  type="search"
                  name="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </form>
          </div>
        </div>

        <main className="flex-grow flex flex-col overflow-hidden">
          <div className="relative isolate overflow-hidden pt-6 flex-grow flex flex-col">
            {/* Secondary navigation */}
            <header className="pb-4 sm:pb-6">
              <div className="mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
                {/* <h1 className="text-base font-semibold leading-7 text-gray-900">
                  Home
                </h1> */}
                <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto  sm:border-gray-200 sm:pl-6 sm:leading-7">
                  {secondaryNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={
                        item.name === activeTab
                          ? "text-blue-500"
                          : "text-gray-700"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        handleTabClick(item.name);
                      }}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </header>

            {/* Stats */}
            <div className="bg-white shadow-sm ring-1 ring-gray-900/5">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <dl
                  className="grid grid-cols-2 gap-x-4 gap-y-3 py-4 
                  sm:grid-cols-3 
                  md:grid-cols-5 
                  xl:grid-cols-9"
                >
                  {stats.map((stat) => (
                    <Tooltip key={stat.name} title={stat.name} arrow>
                      <div className="flex items-center gap-x-2 h-8">
                        <dt className="flex items-center">
                          <stat.icon
                            className={`h-5 w-5 ${stat.color}`}
                            aria-hidden="true"
                          />
                          <p className="ml-2 text-sm font-medium text-gray-600 truncate">
                            {stat.name}
                          </p>
                        </dt>
                        <dd className="text-sm font-semibold text-gray-900">
                          {stat.value}
                        </dd>
                      </div>
                    </Tooltip>
                  ))}
                </dl>
              </div>
            </div>

            {/* Takedown list */}
            <div className="mx-auto w-full max-w-7xl px-4 sm:px-6 lg:px-8 mt-8 flex-grow flex flex-col overflow-hidden">
              <div className="flex justify-between items-center mb-4">
                <div className="flex items-center space-x-4">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    {activeTab} {/* Loading indicator */}
                    {isLoading && (
                      <div className="inline-block ml-2">
                        <CircularProgress size={24} color="primary" />
                      </div>
                    )}
                  </h2>
                  {(activeTab === "My Community" ||
                    activeTab === "All Bonuses") && (
                    <CommunityDropdown
                      selectedCommunities={
                        activeTab === "All Bonuses"
                          ? tempSelectedCommunities
                          : localSelectedCommunities
                      }
                      onChange={handleCommunityChange}
                      onClear={() => fetchBonusData(0)}
                      communities={communities2}
                    />
                  )}
                  {/* Add Export to PDF button with Tooltip */}
                  <Tooltip title="Export to PDF" arrow>
                    <button
                      onClick={handleExportToPDF}
                      className="inline-flex items-center px-2 py-1.5 shadow-sm text-sm leading-4 font-medium rounded-md bg-white hover:bg-gray-50 border border-gray-300 text-black"
                    >
                      <ArrowDownTrayIcon
                        className="h-4 w-5 text-primary-500"
                        aria-hidden="true"
                      />
                    </button>
                  </Tooltip>
                </div>
                <div className="flex items-center space-x-4">
                  <SortDropdown
                    sortBy={sortBy}
                    setSortBy={handleSortChange} // Use the new handler instead of setSortBy directly
                  />
                  <DateRangeDropdown
                    selectedRange={selectedDateRange}
                    setSelectedRange={handleDateRangeChange} // Use the new handler
                  />
                </div>
              </div>

              {error && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <strong className="font-bold">Error: </strong>
                  <span className="block sm:inline">{error}</span>
                </div>
              )}

              <div className="flex-grow overflow-auto">
                {isLoading && bonusData.length === 0 ? (
                  // Skeleton loader
                  <div className="flex-grow">
                    {Array.from({ length: 5 }).map((_, index) => (
                      <div
                        key={index}
                        className="animate-pulse flex space-x-4 mb-4"
                      >
                        <div className="rounded-full bg-gray-200 h-12 w-12"></div>
                        <div className="flex-1 space-y-4 py-1">
                          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                          <div className="space-y-2">
                            <div className="h-4 bg-gray-200 rounded"></div>
                            <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : error && bonusData.length === 0 ? (
                  <div className="flex-grow flex items-center justify-center">
                    <p className="text-center text-red-500">{error}</p>
                  </div>
                ) : bonusData.length > 0 ? (
                  <div className="flex-grow">
                    {/* Render rejected items first */}
                    {groupBonusesByDate(filteredBonuses).rejected.length >
                      0 && (
                      <div>
                        <ul className="divide-y divide-gray-200">
                          {groupBonusesByDate(filteredBonuses).rejected.map(
                            (bonus) => (
                              <React.Fragment key={bonus.jobId}>
                                <div
                                  className="relative flex items-center space-x-4 py-4 hover:bg-gray-50 cursor-pointer"
                                  onClick={() =>
                                    toggleRowExpansion(bonus.jobId)
                                  }
                                >
                                  <div className="absolute left-0 inset-y-0 flex items-center justify-center w-8">
                                    {expandedRows.has(bonus.jobId) ? (
                                      <ExpandLess className="h-5 w-5 text-gray-400" />
                                    ) : (
                                      <ExpandMore className="h-5 w-5 text-gray-400" />
                                    )}
                                  </div>
                                  <div className="min-w-0 flex-auto pl-8">
                                    <div className="flex items-center gap-x-3">
                                      <div
                                        className={`flex-none rounded-full p-1 ${
                                          statuses[bonus.statusTypeId] ||
                                          "text-gray-600 bg-gray-100"
                                        }`}
                                      >
                                        <div className="h-2 w-2 rounded-full bg-current" />
                                      </div>
                                      <h2 className="min-w-0 text-sm font-semibold leading-6 text-gray-900">
                                        <span className="truncate">
                                          {bonus.projectNumber}
                                        </span>
                                        <span className="text-gray-400 mx-1">
                                          -
                                        </span>
                                        <span className="whitespace-nowrap">
                                          {bonus.jobNumber}
                                        </span>
                                        <span className="text-gray-400 mx-1">
                                          •
                                        </span>
                                        <span className="text-gray-500 text-sm">
                                          Closed:{" "}
                                          {moment(bonus.closeDate).format(
                                            "YYYY-MM-DD"
                                          ) !== "0001-01-01"
                                            ? moment(bonus.closeDate).format(
                                                "MMM D, YYYY"
                                              )
                                            : "TBD"}
                                        </span>
                                      </h2>
                                    </div>
                                    <div className="mt-3 flex items-center justify-between gap-x-2.5 text-[0.9rem] font-semibold leading-5 text-gray-500">
                                      <div
                                        className="flex items-center gap-x-2.5 min-w-0 cursor-pointer hover:text-blue-600"
                                        onClick={(e) =>
                                          handleTitleClick(e, bonus.jobId)
                                        }
                                      >
                                        <p className="truncate">
                                          {bonus.jobAddress}
                                        </p>
                                        <svg
                                          viewBox="0 0 2 2"
                                          className="h-1 w-1 flex-none fill-gray-300"
                                        >
                                          <circle cx={1} cy={1} r={1} />
                                        </svg>
                                        <p className="whitespace-nowrap">
                                          {bonus.subdivision}
                                        </p>
                                      </div>
                                      <div
                                        className={`shrink-0 rounded-full px-2 py-1 text-xs font-medium ${
                                          statuses[bonus.statusTypeId] ||
                                          "text-gray-600 bg-gray-100"
                                        }`}
                                      >
                                        {formatStatus(
                                          bonus.statusTypeId,
                                          bonus
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            )
                          )}
                        </ul>
                      </div>
                    )}

                    {/* Render other items grouped by date */}
                    {Object.entries(
                      groupBonusesByDate(filteredBonuses).byDate
                    ).map(([date, bonuses]) => (
                      <div key={date}>
                        <h3 className="text-sm font-semibold text-gray-900 my-4">
                          {formatDateHeader(date)}
                        </h3>
                        <ul className="divide-y divide-gray-200">
                          {bonuses.map((bonus) => (
                            <React.Fragment key={bonus.jobId}>
                              <div
                                className="relative flex items-center space-x-4 py-4 hover:bg-gray-50 cursor-pointer"
                                onClick={() => toggleRowExpansion(bonus.jobId)}
                              >
                                <div className="absolute left-0 inset-y-0 flex items-center justify-center w-8">
                                  {expandedRows.has(bonus.jobId) ? (
                                    <ExpandLess className="h-5 w-5 text-gray-400" />
                                  ) : (
                                    <ExpandMore className="h-5 w-5 text-gray-400" />
                                  )}
                                </div>
                                <div className="min-w-0 flex-auto pl-8">
                                  <div className="flex items-center gap-x-3">
                                    <div
                                      className={`flex-none rounded-full p-1 ${
                                        statuses[bonus.statusTypeId] ||
                                        "text-gray-600 bg-gray-100"
                                      }`}
                                    >
                                      <div className="h-2 w-2 rounded-full bg-current" />
                                    </div>
                                    <h2 className="min-w-0 text-sm font-semibold leading-6 text-gray-900">
                                      <span className="truncate">
                                        {bonus.projectNumber}
                                      </span>
                                      <span className="text-gray-400 mx-1">
                                        -
                                      </span>
                                      <span className="whitespace-nowrap">
                                        {bonus.jobNumber}
                                      </span>
                                    </h2>
                                  </div>
                                  <div className=" flex items-center justify-between gap-x-2.5 text-[0.9rem] font-semibold leading-5 text-gray-500">
                                    <div
                                      className="flex items-center gap-x-2.5 min-w-0 cursor-pointer hover:text-blue-600"
                                      onClick={(e) =>
                                        handleTitleClick(e, bonus.jobId)
                                      }
                                    >
                                      <p className="truncate">
                                        {bonus.jobAddress}
                                      </p>
                                      <svg
                                        viewBox="0 0 2 2"
                                        className="h-1 w-1 flex-none fill-gray-300"
                                      >
                                        <circle cx={1} cy={1} r={1} />
                                      </svg>
                                      <p className="whitespace-nowrap">
                                        {bonus.subdivision}
                                      </p>
                                    </div>
                                    <div>
                                      <div className="mb-1 text-gray-500 text-sm">
                                        Closed Date:{" "}
                                        {moment(bonus.closeDate).format(
                                          "YYYY-MM-DD"
                                        ) !== "0001-01-01"
                                          ? moment(bonus.closeDate).format(
                                              "MMM D, YYYY"
                                            )
                                          : "TBD"}
                                      </div>
                                      <Chip
                                        size="small"
                                        label={formatStatus(
                                          bonus.statusTypeId,
                                          bonus
                                        )}
                                        color="primary"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {expandedRows.has(bonus.jobId) && (
                                <DetailPanel bonus={bonus} />
                              )}
                            </React.Fragment>
                          ))}
                        </ul>
                      </div>
                    ))}
                    {hasMore && (
                      <div ref={ref} className="flex justify-center py-6">
                        {isLoading ? (
                          <div className="flex items-center space-x-2">
                            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500"></div>
                            <span className="text-gray-600">
                              Loading more...
                            </span>
                          </div>
                        ) : (
                          <div className="flex flex-col items-center">
                            <span className="text-gray-600 mb-2">
                              Scroll for more
                            </span>
                            <svg
                              className="w-6 h-6 text-blue-500 animate-bounce"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                            </svg>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="flex-grow flex items-center justify-center">
                    <p className="text-center text-gray-500">
                      No takedowns found for the selected criteria.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>

      <FilterModal
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        onApply={handleApplyFilters}
      />
    </div>
  );
};

export default Home;
