import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useData } from "../../providers/DataProvider";
import logo from "../../assets/images/logo-white.png";
import { SignOutButton } from "../Buttons/SignOutButton";
import RegionSwitcher from "../RegionSwitcher";
import BonusTypeSwitcher from "../BonusTypeSwitcher";

const Header: React.FC = () => {
  const { accounts } = useMsal();
  const { setRole, role, loadDataForRole } = useData();
  const [isRoleMenuOpen, setIsRoleMenuOpen] = useState(false);
  const roleMenuRef = useRef<HTMLDivElement>(null);
  const [isConsolidatedMenuOpen, setIsConsolidatedMenuOpen] = useState(false);
  const consolidatedMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        roleMenuRef.current &&
        !roleMenuRef.current.contains(event.target as Node)
      ) {
        setIsRoleMenuOpen(false);
      }

      if (
        consolidatedMenuRef.current &&
        !consolidatedMenuRef.current.contains(event.target as Node)
      ) {
        setIsConsolidatedMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleRoleMenu = () => setIsRoleMenuOpen(!isRoleMenuOpen);
  const toggleConsolidatedMenu = () =>
    setIsConsolidatedMenuOpen(!isConsolidatedMenuOpen);

  const handleRoleSelect = (selectedRole: string) => {
    setRole(selectedRole);
    setIsRoleMenuOpen(false);
    loadDataForRole(selectedRole);
  };

  const [bonusType, setBonusType] = useState<string>(
    localStorage.getItem("bonusType") || "ClosingBonus"
  );

  return (
    <nav className="bg-primary-500 border-b border-gray-200 px-4 py-2.5 fixed left-0 right-0 top-0 z-50">
      <div className="flex flex-wrap justify-between items-center">
        <div className="flex justify-start items-center">
          <Link to="/" className="flex items-center justify-between mr-4">
            <img src={logo} className="mr-3 h-11" alt="Logo" />
            <span className="self-center text-xl font-semibold whitespace-nowrap text-white">
              Construction Bonus
            </span>
          </Link>
        </div>
        <div className="flex items-center lg:order-2">
          {/* Individual switchers - visible when width ≥ 840px */}
          <div className="hidden [@media(min-width:840px)]:block mr-4">
            <RegionSwitcher />
          </div>
          <div className="hidden [@media(min-width:840px)]:block mr-4">
            <BonusTypeSwitcher />
          </div>
          <div
            className="hidden [@media(min-width:840px)]:block relative mr-4"
            ref={roleMenuRef}
          >
            <button
              onClick={toggleRoleMenu}
              className="flex items-center justify-center bg-primary-600 hover:bg-primary-700 text-white font-medium py-2 px-4 rounded-md transition duration-150 ease-in-out"
            >
              <span className="mr-2">{role || "Select Role"}</span>
              <svg
                className={`w-4 h-4 transition-transform duration-200 ${
                  isRoleMenuOpen ? "transform rotate-180" : ""
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            {isRoleMenuOpen && (
              <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
                {accounts[0].idTokenClaims &&
                  accounts[0].idTokenClaims?.roles
                    ?.filter((r) => {
                      // Always exclude Admin
                      if (r === "Admin") return false;
                      
                      // If bonusType is NOT "ClosingBonus", also exclude Ops and OpsPlus
                      if (bonusType !== "ClosingBonus" && (r === "Ops" || r === "OpsPlus" || r === "Uploader")) {
                        return false;
                      }
                      
                      // Include all other roles
                      return true;
                    })
                    ?.sort((a, b) => a.localeCompare(b))
                    ?.map((r, index) => (
                      <button
                        key={index}
                        onClick={() => handleRoleSelect(r)}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left transition duration-150 ease-in-out"
                      >
                        {r}
                      </button>
                    ))}
              </div>
            )}
          </div>

          {/* Consolidated menu - visible when width < 840px */}
          <div
            className="[@media(min-width:840px)]:hidden relative mr-4"
            ref={consolidatedMenuRef}
          >
            <button
              onClick={toggleConsolidatedMenu}
              className="flex items-center justify-center bg-primary-600 hover:bg-primary-700 text-white font-medium py-2 px-4 rounded-md transition duration-150 ease-in-out"
            >
              <span className="mr-2">Options</span>
              <svg
                className={`w-4 h-4 transition-transform duration-200 ${
                  isConsolidatedMenuOpen ? "transform rotate-180" : ""
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            {isConsolidatedMenuOpen && (
              <div className="absolute right-0 mt-2 py-2 w-64 bg-white rounded-md shadow-xl z-20">
                <div className="px-4 py-2 border-b border-gray-200">
                  <p className="text-sm font-medium text-gray-500">Region</p>
                  <div className="mt-1">
                    <RegionSwitcher />
                  </div>
                </div>
                <div className="px-4 py-2 border-b border-gray-200">
                  <p className="text-sm font-medium text-gray-500">
                    Bonus Type
                  </p>
                  <div className="mt-1">
                    <BonusTypeSwitcher />
                  </div>
                </div>
                <div className="px-4 py-2">
                  <p className="text-sm font-medium text-gray-500">Role</p>
                  <div className="mt-1">
                    <div className="w-full">
                      <button
                        onClick={toggleRoleMenu}
                        className="flex items-center justify-between w-full bg-primary-600 hover:bg-primary-700 text-white font-medium py-2 px-4 rounded-md transition duration-150 ease-in-out"
                      >
                        <span>{role || "Select Role"}</span>
                        <svg
                          className={`w-4 h-4 transition-transform duration-200 ${
                            isRoleMenuOpen ? "transform rotate-180" : ""
                          }`}
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </button>
                      
                      {isRoleMenuOpen && (
                        
                        <div className="mt-1 py-1 w-full bg-white rounded-md border border-gray-200">
                          {accounts[0].idTokenClaims &&
                            accounts[0].idTokenClaims?.roles
                              ?.filter((r) => {
                                // Always exclude Admin
                                if (r === "Admin") return false;
                                
                                // If bonusType is NOT "ClosingBonus", also exclude Ops and OpsPlus
                                if (bonusType !== "ClosingBonus" && (r === "Ops" || r === "OpsPlus" || r === "Uploader")) {
                                  return false;
                                }
                                
                                // Include all other roles
                                return true;
                              })
                              ?.sort((a, b) => a.localeCompare(b))
                              ?.map((r, index) => (
                                <button
                                  key={index}
                                  onClick={() => handleRoleSelect(r)}
                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left transition duration-150 ease-in-out"
                                >
                                  {r}
                                </button>
                              ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <SignOutButton />
        </div>
      </div>
    </nav>
  );
};

export default Header;