/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect, useContext } from "react";
import axiosInstance from "../utils/axiosInstance";
import { UIContext } from "./UIProvider";
import { JobProps } from "../types";
import {
  RegionProps,
  BonusProps,
  CompanyProps,
  CommunitiesProps,
  ProjectsProps,
} from "./types";

interface DataContextType {
  regions: RegionProps[];
  bonuses: BonusProps[];
  loadingBonuses: boolean;
  loadingCompanies: boolean;
  companies: CompanyProps[];
  communities: CommunitiesProps[];
  communities2: CommunitiesProps[];
  jobs: JobProps[];
  allJobs: JobProps[];
  selectedRegion: RegionProps | null;
  selectedCompany: CompanyProps | null;
  selectedCommunity: CommunitiesProps | null;
  selectedJob: JobProps | null;
  projects: ProjectsProps[];
  role: string | null;
  setRole: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedRegion: React.Dispatch<React.SetStateAction<RegionProps | null>>;
  setSelectedCompany: React.Dispatch<React.SetStateAction<CompanyProps | null>>;
  setSelectedCommunity: React.Dispatch<
    React.SetStateAction<CommunitiesProps | null>
  >;
  setSelectedJob: React.Dispatch<React.SetStateAction<JobProps | null>>;
  loadJobsByProject: () => void;
  loadBonuses: (role: string) => void;
  loadRegions: () => void;
  loadCompanies: () => void;
  loadJobs: () => void;
  loadCommunities: () => void;
  loadProjects: () => void;
  updateSelectedRegion: (region: RegionProps) => void;
  loadDataForRole: (role: string) => void;
  bonusType: string;
  setBonusType: (type: string) => void;
}

const defaultState: DataContextType = {
  regions: [],
  bonuses: [],
  loadingBonuses: false,
  loadingCompanies: false,
  companies: [],
  communities: [],
  communities2: [],
  jobs: [],
  allJobs: [],
  projects: [],
  selectedRegion: null,
  selectedCompany: null,
  selectedCommunity: null,
  selectedJob: null,
  role: null,
  setRole: () => {},
  setSelectedRegion: () => {},
  setSelectedCompany: () => {},
  setSelectedCommunity: () => {},
  setSelectedJob: () => {},
  loadJobsByProject: () => {},
  loadBonuses: () => {},
  loadRegions: () => {},
  loadCompanies: () => {},
  loadJobs: () => {},
  loadCommunities: () => {},
  loadProjects: () => {},
  updateSelectedRegion: () => {},
  loadDataForRole: () => {},
  bonusType: "ClosingBonus",
  setBonusType: () => {},
};

const DataContext = createContext<DataContextType>(defaultState);

//@ts-ignore
export const DataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const getFromStorage = (key: string) => {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : [];
  };

  const getFromStorage2 = (key: string) => {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : null;
  };

  const getFromStorage3 = (key: string) => {
    const storedData = localStorage.getItem(key);
    return storedData || null;
  };
  const [state, dispatch]: any = useContext(UIContext);
  const [bonuses, setBonuses] = useState<BonusProps[]>([]);
  const [loadingBonuses, setLoadingBonuses] = useState<boolean>(false);
  const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false);
  const [role, setRole] = useState<string | null>(getFromStorage3("role"));
  const [regions, setRegions] = useState(getFromStorage("regions"));
  const [companies, setCompanies] = useState<CompanyProps[]>([]);
  const [allCompanies, setAllCompanies] = useState<CompanyProps[]>(
    getFromStorage("companies")
  );
  const [communities, setCommunities] = useState<CommunitiesProps[]>([]);
  const [communities2, setCommunities2] = useState<CommunitiesProps[]>([]);
  const [jobs, setJobs] = useState<JobProps[]>([]);
  const [allJobs, setAllJobs] = useState<JobProps[]>(getFromStorage("allJobs"));
  const [projects, setProjects] = useState<ProjectsProps[]>([]);
  const [bonusType, setBonusType] = useState<string>(
    localStorage.getItem("bonusType") || "ClosingBonus"
  );

  const [selectedRegion, setSelectedRegion] = useState<RegionProps | null>(
    getFromStorage2("selectedRegion")
  );
  const [selectedCompany, setSelectedCompany] = useState<CompanyProps | null>(
    getFromStorage2("selectedCompany")
  );
  const [selectedCommunity, setSelectedCommunity] =
    useState<CommunitiesProps | null>(getFromStorage("selectedCommunity"));
  const [selectedJob, setSelectedJob] = useState<JobProps | null>(null);

  const loadBonuses = (role: string) => {
    if (loadingBonuses) {
      return; // Exit if bonuses are already loading
    }

    setLoadingBonuses(true); // Start loading

    axiosInstance
      .get(`/api/cba/bonuses`, {
        headers: {
          roleType: role,
        },
      })
      .then((res) => {
        if (Array.isArray(res.data)) {
          setBonuses(res.data);
        } else {
          dispatch({
            type: "Snackbar",
            payload: {
              show: true,
              message:
                "An error occurred while loading bonuses. Please try again later.",
              severity: "error",
            },
          });
          setBonuses([]);
        }
        setLoadingBonuses(false);
      })
      .catch((error) => {
        console.error("Error loading bonuses:", error);
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message:
              "An error occurred while loading bonuses. Please try again later.",
            severity: "error",
          },
        });
        setBonuses([]);
        setLoadingBonuses(false);
      });
  };

  const loadRegions = async () => {
    const [regionRes] = await Promise.all([
      axiosInstance.get("/api/cba/regions"),
    ]);
    const regionsData = regionRes.data.sort(
      (a: { regionName: string }, b: { regionName: any }) =>
        a.regionName.localeCompare(b.regionName)
    );
    if (!selectedRegion && regionsData.length > 0) {
      setSelectedRegion(regionsData[1]);
      localStorage.setItem("selectedRegion", JSON.stringify(regionsData[1]));
    }
    setRegions(regionsData);
    localStorage.setItem("regions", JSON.stringify(regionRes.data));
  };

  function findCompanyByRegion(
    companies: CompanyProps[],
    regionId: number
  ): CompanyProps | undefined {
    // Iterate through each company in the array
    for (let company of companies) {
      // Split the regionIds string into an array of strings
      let regions = company.regionIds.split(", ");

      // Check if the regionId exists in the split array
      if (regions.includes(regionId.toString())) {
        return company;
      }
    }

    // Return undefined if no company is found with the given regionId
    return undefined;
  }

  const updateSelectedRegion = async (region: RegionProps) => {
    if (allCompanies) {
      const company = findCompanyByRegion(
        allCompanies,
        region.regionId as number
      );
      setSelectedCompany(company as CompanyProps);
      localStorage.setItem("selectedCompany", JSON.stringify(company));
      setSelectedRegion(region);
      localStorage.setItem("selectedRegion", JSON.stringify(region));

      // Reload relevant data
      await loadCompanies();
      await loadCommunities();
      await loadProjects();
      if (role) {
        loadBonuses(role);
      }
      loadJobs();

      // Schedule a hard reload after 500ms (half a second)
    }
  };

  const loadCompanies = async () => {
    if (loadingCompanies) {
      return; // Exit if companies are already loading
    }

    setLoadingCompanies(true); // Start loading

    if (localStorage.getItem("selectedCompany")) {
      const selectedCo = JSON.parse(
        localStorage.getItem("selectedCompany") as any
      );
      setCompanies([selectedCo]);
    }

    try {
      const [companiesRes] = await Promise.all([
        axiosInstance.get("/api/cba/companies"),
      ]);
      const companiesData = companiesRes.data;

      if (!selectedCompany && companiesData.length > 0) {
        setSelectedCompany(companiesData[0]);
        localStorage.setItem(
          "selectedCompany",
          JSON.stringify(companiesData[0])
        );
      }

      setAllCompanies(companiesData);
      localStorage.setItem("companies", JSON.stringify(companiesData));
    } catch (error) {
      console.error("Error loading companies:", error);
    } finally {
      setLoadingCompanies(false); // Stop loading
    }
  };

  const loadJobs = async () => {
    if (localStorage.getItem("allJobs")) {
      const allJ = JSON.parse(localStorage.getItem("allJobs") as any);
      setAllJobs(allJ);
    }

    try {
      const allJobsRes = await axiosInstance.get("/api/cba/jobs");

      if (Array.isArray(allJobsRes.data)) {
        // Process the jobs data as before
        const jobsData = allJobsRes.data;

        setAllJobs(jobsData);
        localStorage.setItem("allJobs", JSON.stringify(jobsData));
      } else {
        // Log the error but don't set jobs or show a snackbar
        console.error(
          "Error loading jobs: Unexpected response format",
          allJobsRes
        );
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
      dispatch({
        type: "Snackbar",
        payload: {
          show: true,
          message:
            "An error occurred while loading jobs. Please try again later.",
          severity: "error",
        },
      });
    }
  };

  const loadCommunities = async () => {
    if (localStorage.getItem("communities")) {
      const allJ = JSON.parse(localStorage.getItem("communities") as any);
      setCommunities(
        allJ.sort((a: { subDivision: string }, b: { subDivision: any }) =>
          a.subDivision.localeCompare(b.subDivision)
        )
      );
      setCommunities2(
        allJ
          .reduce(
            (acc: any[], current: { projectId: any; subDivision: string }) => {
              if (!acc.find((item) => item.projectId === current.projectId)) {
                acc.push(current);
              }
              return acc;
            },
            []
          )
          .sort((a: { subDivision: string }, b: { subDivision: any }) =>
            a.subDivision.localeCompare(b.subDivision)
          )
      );
    }

    if (selectedCompany && selectedRegion) {
      try {
        const res = await axiosInstance.get<CommunitiesProps[]>(
          `/api/cba/communities-summary/${selectedCompany.companyId}/${selectedRegion.regionId}`
        );
        if (res.data.length <= 0) {
          dispatch({
            type: "Snackbar",
            payload: {
              show: true,
              message: "There are no communities available in this region",
              severity: "error",
            },
          });
        }
        setCommunities(res.data);
        setCommunities2(
          res.data
            .reduce(
              (
                acc: any[],
                current: { projectId: any; subDivision: string }
              ) => {
                if (!acc.find((item) => item.projectId === current.projectId)) {
                  acc.push(current);
                }
                return acc;
              },
              []
            )
            .sort((a: { subDivision: string }, b: { subDivision: any }) =>
              a.subDivision.localeCompare(b.subDivision)
            )
        );
        localStorage.setItem("communities", JSON.stringify(res.data));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const loadProjects = async () => {
    if (localStorage.getItem("projects")) {
      const p = JSON.parse(localStorage.getItem("projects") as any);
      setProjects(p);
    }
    const [projectsRes] = await Promise.all([
      axiosInstance.get("/api/cba/projects"),
    ]);
    const projectsData = projectsRes.data.sort(
      (a: { subdivision: string }, b: { subdivision: any }) =>
        a.subdivision.localeCompare(b.subdivision)
    );

    setProjects(projectsData);
    localStorage.setItem("projects", JSON.stringify(projectsData));
  };

  const loadDataForRole = (role: string) => {
    setRole(role);
    localStorage.setItem("role", role);

    // Reload relevant data
    loadBonuses(role);
    loadRegions();
    loadCompanies();
    loadJobs();
    loadCommunities();
    loadProjects();
  };

  useEffect(() => {
    if (selectedCompany && selectedRegion) {
      loadCompanies();
    }
  }, [selectedRegion, selectedCompany]);

  useEffect(() => {
    if (role) {
      loadDataForRole(role);
    }
  }, [role]);

  const handleBonusTypeChange = (type: string) => {
    setBonusType(type);
    localStorage.setItem("bonusType", type);
  };

  return (
    <DataContext.Provider
      value={{
        regions,
        bonuses,
        loadingBonuses,
        loadingCompanies,
        companies,
        communities,
        communities2,
        jobs,
        allJobs,
        selectedRegion,
        selectedCompany,
        selectedCommunity,
        selectedJob,
        role,
        projects,
        setRole,
        setSelectedRegion,
        setSelectedCompany,
        setSelectedCommunity,
        setSelectedJob,
        loadBonuses,
        loadRegions,
        loadCompanies,
        loadJobs,
        loadCommunities,
        loadProjects,
        updateSelectedRegion,
        loadJobsByProject: () => {
          /* load jobs by project logic */
        },
        loadDataForRole,
        bonusType,
        setBonusType: handleBonusTypeChange,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
