/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  Stack,
  Tooltip,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  AvatarGroup,
  Avatar,
  InputLabel,
  Chip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  ListItemText,
} from "@mui/material";
import NoRows from "../../components/DataGrid/animations/NoRows";
import { useData } from "../../providers/DataProvider";
import { BonusProps, BuilderInfo, RegionProps } from "../../providers/types";
import { ChangeEvent, useEffect, useState } from "react";
import RoleModal from "../../modals/RoleModal";
import ReviewerViewModal from "../../modals/ReviewerViewModal";
import { EyeIcon, DocumentTextIcon } from "@heroicons/react/24/solid";
import PayrollModal from "../../modals/PayrollModal";
import AxiosInstance from "../../utils/axiosInstance";
import BuilderSplitModal from "../../components/BuilderSplitModalReview";
import moment from "moment";
import { SelectChangeEvent } from "@mui/material/Select";
import { MagnifyingGlassIcon, XCircleIcon } from "@heroicons/react/24/outline";
import ReviewTable from "../../components/Tables/ReviewTable";

type FilterItem = {
  id?: string | number;
  field: string;
  operator: string;
  value: string | string[];
};

type Filters = {
  items: FilterItem[];
  quickFilterValues: string[];
  logicOperator: string;
};

const defaultFilter: Filters = {
  items: [],
  quickFilterValues: [""],
  logicOperator: "and",
};

export default function Reviewer() {
  const {
    regions,
    bonuses,
    loadBonuses,
    role,
    loadingBonuses,
    selectedRegion,
    setSelectedRegion,
  } = useData();
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedStatusType, setSelectedStatusType] = React.useState<
    number | string | null
  >(() => {
    const savedStatus = localStorage.getItem("selectedStatusReview");
    return savedStatus ? JSON.parse(savedStatus) : "All";
  });
  const [selectedCommunities, setSelectedCommunities] = useState<string[]>(
    () => {
      const savedCommunities = localStorage.getItem(
        "selectedCommunitiesReview"
      );
      return savedCommunities ? JSON.parse(savedCommunities) : [];
    }
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  const handleStatusTypeChange = (event: any) => {
    const value = event.target.value;
    setSelectedStatusType(value);
    localStorage.setItem("selectedStatusReview", JSON.stringify(value));
  };

  const handleCommunityChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    if (value.includes("clear-all")) {
      setSelectedCommunities([]);
      localStorage.setItem("selectedCommunitiesReview", JSON.stringify([]));
    } else {
      setSelectedCommunities(typeof value === "string" ? [value] : value);
      localStorage.setItem(
        "selectedCommunitiesReview",
        JSON.stringify(typeof value === "string" ? [value] : value)
      );
    }
  };

  const getUniqueCommunities = () => {
    const filteredBonuses =
      selectedRegion && selectedRegion.regionId !== "All"
        ? bonuses.filter(
            (bonus) => bonus.regionName === selectedRegion.regionName
          )
        : bonuses;

    const communities = filteredBonuses
      .map((bonus) => bonus.subdivision)
      .filter((subdivision): subdivision is string => !!subdivision);

    const uniqueSortedCommunities = Array.from(new Set(communities)).sort(
      (a, b) => a.localeCompare(b)
    );

    return ["All", ...uniqueSortedCommunities];
  };

  const formatStatus = (status: number, bonus?: BonusProps): string => {
    const isDefaultDate = (date: string | Date) =>
      moment(date).isSame(moment("0001-01-01"));

    console.log("formatSStatus: " + status);
    switch (status) {
      case 0:
        return "Not Started";
      case 1:
        return "In Upload Process";
      case 2:
        return "PM Review";
      case 3:
        return "SPM Review";
      case 4:
        return "DM Review";
      case 5:
        return "Ops Review";
      case 6:
        return "Ops Approved";
      case 7:
        return "Rejected";
      case 8:
        return "Busted";
      case 9:
        return bonus?.payrollProcessedOn &&
          !isDefaultDate(bonus.payrollProcessedOn)
          ? `In Payroll Process (${moment(bonus.payrollProcessedOn).format(
              "MM/DD/YYYY"
            )})`
          : "In Payroll Process";
      case 10:
        return bonus?.payrollPaidOn && !isDefaultDate(bonus.payrollPaidOn)
          ? `Paid (${moment(bonus.payrollPaidOn).format("MM/DD/YYYY")})`
          : "Paid";
      default:
        return "Unknown Status";
    }
  };

  return (
    <Container
      component="main"
      maxWidth={false}
      sx={{
        height: "calc(100vh - 56px)",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        pt: 2,
        pb: 2,
      }}
    >
      <div className="flex-grow flex flex-col overflow-hidden bg-white rounded-lg shadow-md">
        <div className="p-4 flex-shrink-0">
          <div className="flex flex-col sm:flex-row justify-between gap-3">
            <div className="flex flex-row items-start gap-3 w-full md:w-3/5 lg:w-1/2">
              <div className="relative w-full max-w-[300px]">
                <div className="relative">
                  <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                  <input
                    type="text"
                    className="w-full pl-10 pr-10 h-10 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary-500"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setTimeout(() => setIsFocused(false), 200)}
                  />
                  {searchTerm && (
                    <button
                      onClick={clearSearch}
                      className="absolute right-3 top-1/2 transform -translate-y-1/2"
                    >
                      <XCircleIcon className="h-5 w-5 text-primary-500 hover:text-primary-700" />
                    </button>
                  )}
                </div>
              </div>
              <FormControl sx={{ width: 300 }} size="small">
                <InputLabel id="community-select-label">Community</InputLabel>
                <Select
                  labelId="community-select-label"
                  size="small"
                  label="Community"
                  multiple
                  value={selectedCommunities}
                  onChange={handleCommunityChange}
                  renderValue={(selected) => {
                    const selectedCount = selected.length;
                    if (selectedCount === 1) {
                      return selected[0];
                    } else if (selectedCount > 1) {
                      return `${selectedCount} Communities Selected`;
                    } else {
                      return (
                        <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                          Select Communities
                        </span>
                      );
                    }
                  }}
                  sx={{ height: "40px" }}
                >
                  <MenuItem value="clear-all">
                    <em>Clear All</em>
                  </MenuItem>
                  {getUniqueCommunities()
                    .filter((community) => community !== "All")
                    .map((community) => (
                      <MenuItem key={community} value={community}>
                        <Checkbox
                          checked={selectedCommunities.indexOf(community) > -1}
                        />
                        <ListItemText primary={community} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: 300 }}>
                <InputLabel id="status-select-label">Status</InputLabel>
                <Select
                  labelId="status-select-label"
                  size="small"
                  label="Status"
                  value={selectedStatusType || ""}
                  onChange={handleStatusTypeChange}
                  sx={{ height: "40px" }}
                >
                  <MenuItem value="All">
                    <em>All</em>
                  </MenuItem>
                  {bonuses
                    .reduce((unique, bonus) => {
                      if (
                        !unique.some(
                          (obj) => obj.statusTypeId === bonus.statusTypeId
                        )
                      ) {
                        unique.push(bonus);
                      }
                      return unique;
                    }, [] as typeof bonuses)
                    .sort((a, b) => a.statusTypeId - b.statusTypeId)
                    .map((uniqueBonus) => (
                      <MenuItem
                        key={uniqueBonus.statusTypeId}
                        value={uniqueBonus.statusTypeId}
                      >
                        {formatStatus(uniqueBonus.statusTypeId)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <div className="flex-grow overflow-auto relative">
          <ReviewTable
            data={bonuses}
            role={role}
            loading={loadingBonuses}
            searchTerm={searchTerm}
            selectedStatusType={selectedStatusType}
            selectedCommunities={selectedCommunities}
            selectedRegion={selectedRegion}
          />
        </div>
      </div>
      <RoleModal open={open} setOpen={setOpen} />
    </Container>
  );
}
