import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  Theme,
  Paper,
  Grid,
  TextField,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  Button,
  Tooltip,
  CircularProgress,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Stack,
  Divider,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { useData } from "../../providers/DataProvider";
import {
  fileAttachmentsByTypesProps,
  fileAttachmentsProps,
  JobDetailsProps,
} from "../../providers/types";
import theme from "../../theme";
import axiosInstance from "../../utils/axiosInstance";
import { useMsal } from "@azure/msal-react";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

interface Props {
  jobId: number;
}

const ReviewerViewModal = ({ jobId }: Props) => {
  const { loadBonuses, role, bonusType } = useData();
  const { accounts } = useMsal();
  const [open, setOpen] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [files, setFiles] = useState<any[]>([]);
  const [job, setJob] = useState<JobDetailsProps | undefined>(undefined);
  const [reviewDecision, setReviewDecision] = useState("");
  const [commentText, setCommentText] = useState("");
  const [fileCommentText, setFileCommentText] = useState("");
  const [fileStreamUrl, setFileStreamUrl] = useState<string | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);
  const [viewHistory, setViewHistory] = useState(false);
  const [viewPending, setViewPending] = useState(true);
  const [historyComments, setHistoryComments] = useState<
    {
      action: string;
      comment: string;
      createdBy: string;
      createdOn: string;
      fileAttachmentId: number;
      fileAttachmentName: string;
      fileAttachmentTypeName: string;
      id: number;
      jobId: number;
      roleTypeId: number;
    }[]
  >([]);
  const [filecomments, setFileComments] = useState<
    {
      Id: string;
      FileAttachmentId: number;
      Comment: string;
      Name: string;
    }[]
  >([]);
  const [isReadyOnly, setIsReadyOnly] = useState<boolean>(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFiles([]);
    setFileStreamUrl(undefined);
    if (fileStreamUrl) {
      URL.revokeObjectURL(fileStreamUrl); // Revoke the object URL to free up memory
    }
  };

  const generateUniqueId = () => {
    return `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
  };

  const handleNext = () => {
    if (files && currentFileIndex < files.length - 1) {
      setCurrentFileIndex(currentFileIndex + 1);
      fetchFileStream(files[currentFileIndex + 1].fileAttachmentId);
    }
  };

  const handlePrevious = () => {
    if (currentFileIndex > 0) {
      setCurrentFileIndex(currentFileIndex - 1);
      fetchFileStream(files[currentFileIndex - 1].fileAttachmentId);
    }
  };

  const handleAddFileComment = () => {
    if (!fileCommentText.trim()) {
      alert("Please enter a comment");
      return;
    }

    // Only process file comments if we have files (not Stage5Bonus)
    if (files.length > 0) {
      const currentFile = files[currentFileIndex];
      const fileType = job?.fileAttachmentTypes.find(
        (type) =>
          type.fileAttachmentTypesId === currentFile?.fileAttachmentTypeId
      );

      setFileComments([
        ...filecomments,
        {
          Id: generateUniqueId(),
          FileAttachmentId: files[currentFileIndex]?.fileAttachmentId,
          Comment: fileCommentText,
          Name: fileType ? `${fileType.name} ${currentFile.fileName}` : "",
        },
      ]);
    } else {
      // For Stage5Bonus, just add a general comment without file reference
      setFileComments([
        ...filecomments,
        {
          Id: generateUniqueId(),
          FileAttachmentId: 0,
          Comment: fileCommentText,
          Name: "General Comment",
        },
      ]);
    }

    setFileCommentText("");
  };

  const handleSubmit = () => {
    if (!reviewDecision) {
      alert("Please select approve or reject.");
      return;
    }

    if (reviewDecision === "reject" && !commentText.trim()) {
      alert("Comment is required for rejection.");
      return;
    }

    if (window.confirm("Are you sure you want to submit your review?")) {
      const updateData = {
        JobId: jobId,
        BonusStatus: reviewDecision === "reject" ? "Reject" : "Approve",
        UpdatedBy: accounts[0].username,
        Comment: commentText,
        FileAttachmentComments: filecomments,
      };

      axiosInstance
        .put(`/api/cba/bonuses/${jobId}`, updateData)
        .then(() => {
          if (role) {
            loadBonuses(role);
          }
        })
        .catch((error) => {
          console.error("Error submitting review:", error);
        });

      setOpen(false);
      setCommentText("");
      setReviewDecision("");
    }
  };

  const fetchFileStream = (fileAttachmentId: number) => {
    setLoading(true);
    axiosInstance
      .get(`/api/cba/attachmentfile/${fileAttachmentId}`, {
        responseType: "blob",
      })
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        setFileStreamUrl(url);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching file stream:", error);
        setLoading(false);
      });
  };

  const loadJobDetails = (jobId: number, role: string | null) => {
    axiosInstance
      .get(`/api/cba/job-details/${jobId}`, {
        headers: {
          roleType: role,
        },
      })
      .then((response) => {
        setJob(response.data);
        setIsReadyOnly(response.data.bonusDetail.isReadyOnly);

        // Skip loading files if it's a Stage5Bonus type
        if (bonusType !== "Stage5Bonus") {
          let fileAttachments: fileAttachmentsProps[] = [];
          if (response?.data?.fileAttachmentsByTypes?.length > 0) {
            response.data.fileAttachmentsByTypes.forEach(
              (fat: fileAttachmentsByTypesProps) => {
                if (fat?.fileAttachments?.length > 0) {
                  const hasMergedFile = fat.fileAttachments.find(
                    (file) => file.isMergedFile
                  );
                  if (hasMergedFile) {
                    fileAttachments.push(hasMergedFile);
                  } else {
                    fat.fileAttachments.forEach((fa) => {
                      fileAttachments.push(fa);
                    });
                  }
                }
              }
            );
          }

          setFiles(fileAttachments);
          if (fileAttachments.length > 0) {
            fetchFileStream(fileAttachments[0].fileAttachmentId);
          }
        } else {
          // For Stage5Bonus, set an empty files array
          setFiles([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching job details:", error);
      });
  };

  const loadComments = async (jobId: number) => {
    if (jobId) {
      axiosInstance.get(`/api/cba/comments/${jobId}`).then((response) => {
        setHistoryComments(response.data);
      });
    }
  };

  useEffect(() => {
    if (open) {
      loadJobDetails(jobId, role);
      loadComments(jobId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, jobId, role]);

  const isPdfFile = files[currentFileIndex]?.fileName
    .toLowerCase()
    .endsWith(".pdf");

  return (
    <>
     <Tooltip title="Review">
      <IconButton onClick={handleOpen} size="small">
        <RateReviewIcon className="h-5 w-5 text-primary-600" />
      </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={bonusType === "Stage5Bonus" ? "md" : "xl"}
        fullWidth
      >
        {job && (
          <Grid container>
            {/* Left Panel - PDF Viewer - Only shown when not Stage5Bonus */}
            {bonusType !== "Stage5Bonus" && (
              <Grid item xs={8} sx={{ borderRight: 1, borderColor: "divider" }}>
                <DialogTitle
                  sx={{ borderBottom: 1, borderColor: "divider", p: 2 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6">
                      {job.jobAddress} - {job.subdivision}
                    </Typography>
                    <IconButton onClick={handleClose} size="small">
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </DialogTitle>

                {/* File Navigation */}
                <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      onClick={handlePrevious}
                      disabled={currentFileIndex === 0 || loading}
                    >
                      <NavigateBeforeIcon />
                    </IconButton>
                    <Typography
                      variant="subtitle1"
                      component="div"
                      textAlign="center"
                    >
                      {files[currentFileIndex]
                        ? `${
                            job?.fileAttachmentTypes.find(
                              (type) =>
                                type.fileAttachmentTypesId ===
                                files[currentFileIndex].fileAttachmentTypeId
                            )?.name || ""
                          } - ${files[currentFileIndex].fileName}`
                        : "No File"}
                    </Typography>
                    <IconButton
                      onClick={handleNext}
                      disabled={
                        currentFileIndex === files.length - 1 || loading
                      }
                    >
                      <NavigateNextIcon />
                    </IconButton>
                  </Box>
                </Box>

                {/* PDF Viewer */}
                <Box sx={{ height: "calc(100vh - 250px)", overflow: "auto" }}>
                  {loading ? (
                    <CircularProgress />
                  ) : isPdfFile ? (
                    <Box
                      sx={{
                        display: "flex",
                        flex: 1,
                        height: "100%",
                      }}
                    >
                      <iframe
                        src={fileStreamUrl}
                        width="100%"
                        height="100%"
                        title="pdf preview"
                      />
                    </Box>
                  ) : (
                    <img
                      src={fileStreamUrl}
                      alt={files[currentFileIndex]?.fileName}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "70vh", // Set max height relative to view height
                        objectFit: "contain",
                      }}
                    />
                  )}
                </Box>
              </Grid>
            )}

            {/* Right Panel - Review Information */}
            <Grid item xs={bonusType === "Stage5Bonus" ? 12 : 4}>
              {bonusType === "Stage5Bonus" && (
                <DialogTitle
                  sx={{ borderBottom: 1, borderColor: "divider", p: 2 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6">
                      {job.jobAddress} - {job.subdivision}
                    </Typography>
                    <IconButton onClick={handleClose} size="small">
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </DialogTitle>
              )}
              <Box sx={{ height: "100vh", overflow: "auto" }}>
                {/* Builder Split Information - Now at the top */}
                <Box
                  sx={{
                    p: 2,
                    bgcolor: "background.paper",
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Builder Split Information
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    <Typography variant="subtitle2">Bonus Amount</Typography>
                    <Typography variant="subtitle1" color="primary.main">
                      $
                      {job?.bonusDetail?.bonus?.closingBonusAmount?.toLocaleString() ||
                        "0"}
                    </Typography>
                  </Box>

                  {job?.bonusDetail?.bonus?.unusedBonusAmount > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 2,
                      }}
                    >
                      <Typography variant="subtitle2">Unused Bonus</Typography>
                      <Typography variant="subtitle1" color="warning.main">
                        $
                        {job?.bonusDetail?.bonus?.unusedBonusAmount?.toLocaleString() ||
                          "0"}
                      </Typography>
                    </Box>
                  )}

                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                      borderRadius: 1,
                      border: 1,
                      borderColor: "divider",
                    }}
                  >
                    {job?.bonusDetail?.bonus?.builders?.map(
                      (builder: any, index: number) => (
                        <ListItem
                          key={index}
                          sx={{
                            flexDirection: "column",
                            alignItems: "flex-start",
                            py: 2,
                            ...(index !==
                              job.bonusDetail.bonus.builders.length - 1 && {
                              borderBottom: 1,
                              borderColor: "divider",
                            }),
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              mb: 1,
                            }}
                          >
                            <Typography variant="subtitle1">
                              {builder.builderName}
                            </Typography>
                          </Box>
                          <Box
                            sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}
                          >
                            <Chip
                              label={`Split: ${builder.split}%`}
                              size="small"
                              color="primary"
                              variant="outlined"
                            />
                            {builder.splitAmount > 0 && (
                              <Chip
                                label={`Amount: $${builder.splitAmount.toLocaleString()}`}
                                size="small"
                                color="primary"
                                variant="outlined"
                              />
                            )}
                            {builder.deductionAmount > 0 && (
                              <Chip
                                label={`Deduction: $${builder.deductionAmount.toLocaleString()}`}
                                size="small"
                                color="error"
                                variant="outlined"
                              />
                            )}
                          </Box>
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            sx={{ mt: 1 }}
                          >
                            {builder.builderEmail}
                          </Typography>
                        </ListItem>
                      )
                    )}
                  </List>
                </Box>

                {/* Comments History Section - Improved UI */}
                <Box sx={{ p: 2 }}>
                  <List>
                    <ListItem
                      button
                      onClick={() => setViewHistory(!viewHistory)}
                      sx={{ bgcolor: "grey.50", borderRadius: 1 }}
                    >
                      <ListItemText primary="Review History" />
                      {viewHistory ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={viewHistory} timeout="auto">
                      <List sx={{ maxHeight: 300, overflow: "auto" }}>
                        {historyComments ? (
                          historyComments.map((comment) => (
                            <Box
                              key={comment?.id}
                              sx={{
                                mt: 1,
                                px: 2,
                                wordWrap: "normal",
                                wordBreak: "break-word",
                              }}
                            >
                              <Typography variant="body2" color="BlackText">
                                {comment?.createdBy}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="GrayText"
                                sx={{
                                  fontSize: "06.rem",
                                }}
                              >
                                {comment?.fileAttachmentTypeName ||
                                  "General Comment"}{" "}
                              </Typography>
                              <Box my={1}>
                                <Typography
                                  variant="caption"
                                  color="CaptionText"
                                  sx={{
                                    fontSize: "08.rem",
                                    pl: 0.5,
                                  }}
                                >
                                  {comment?.comment}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  color="GrayText"
                                  textAlign="right"
                                >
                                  {moment(comment?.createdOn).format("LLL")}
                                </Typography>
                              </Box>
                            </Box>
                          ))
                        ) : (
                          <ListItem>
                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    No comment history
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        )}
                      </List>
                    </Collapse>
                  </List>
                </Box>

                {/* File Comments Section - Improved UI 
                  Only show for non-Stage5Bonus OR if comments exist for Stage5Bonus */}
                {(bonusType !== "Stage5Bonus" || filecomments.length > 0) && (
                  <Box sx={{ p: 2 }}>
                    <List>
                      <ListItem
                        button
                        onClick={() => setViewPending(!viewPending)}
                        sx={{ bgcolor: "grey.50", borderRadius: 1 }}
                      >
                        <ListItemText primary="Pending Comments" />
                        {viewPending ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={viewPending} timeout="auto">
                        <List sx={{ maxHeight: 300, overflow: "auto" }}>
                          {filecomments.length ? (
                            filecomments.map((fileComment) => (
                              <ListItem
                                secondaryAction={
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() =>
                                      setFileComments(
                                        filecomments.filter(
                                          (commentText) =>
                                            commentText.Id !== fileComment.Id
                                        )
                                      )
                                    }
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                }
                              >
                                <ListItemText
                                  secondary={fileComment.Comment}
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        sx={{ display: "inline" }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                        {fileComment.Name || ""}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                              </ListItem>
                            ))
                          ) : (
                            <ListItem>
                              <ListItemText
                                primary={
                                  <React.Fragment>
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      No pending comments
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                          )}
                        </List>
                      </Collapse>
                    </List>
                  </Box>
                )}

                {/* Review Options Section */}
                
                {!(job.reviewStatus === "OpsApproved" ||
                  job.reviewStatus === "InPayrollProcess" ||
                  job.reviewStatus === "Paid") && (
                <Box sx={{ p: 2, bgcolor: "grey.50" }}>
                  <FormControl fullWidth>
                    <FormLabel id="review-options-label" sx={{ mb: 1 }}>
                      Review Decision
                    </FormLabel>
                    <RadioGroup
                      value={reviewDecision}
                      onChange={(e) => setReviewDecision(e.target.value)}
                    >
                      <FormControlLabel
                        value="approve"
                        control={<Radio color="success" />}
                        label="Approve"
                      />
                      {bonusType !== "Stage5Bonus" && (
                        <FormControlLabel
                          value="reject"
                          control={<Radio color="error" />}
                          label="Reject (comment required)"
                        />
                      )}
                    </RadioGroup>
                  </FormControl>

                  <TextField
                    multiline
                    fullWidth
                    minRows={3}
                    label="General Comment"
                    variant="outlined"
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                    sx={{ mt: 2, mb: 2 }}
                  />

                  <Button
                    fullWidth
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Submit Review
                  </Button>
                </Box>
              )}
              </Box>
            </Grid>
          </Grid>
        )}
      </Dialog>
    </>
  );
};

export default ReviewerViewModal;
